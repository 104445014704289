import consumer from "./consumer"

$(document).on('turbolinks:load', function () {
    let channelId = $("meta[name='stream-id']").attr("content")
    createSubscription(channelId)
});

function createSubscription(streamId) {
  consumer.subscriptions.create({channel: "PlanningToolChannel", stream_id: streamId}, {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      planningTool.hideProgressIndicator(data.isSinglePlan)
      if (data.plan) {
        planningTool.planReceived(data)
      } else if (data.error) {
        this.showError(data.error)
      }
    },

    // If there is an error in the planning, unhide the error div and append the error message.
    showError(message) {
      $('#planning-error').removeClass('d-none').children(":first").append('<div>' + message + '</div>')
    }
  });
}
