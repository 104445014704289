// Logic for using geolocation API to verify user is within a certain distance
// from mower and disable mower controls in companion app if user is not near mower.

// Distance the user must be within to use mower controls.
const GEOLOCATION_DISTANCE_MILES = 1

export default class {
  constructor(mowerLat, mowerLng) {
    var _this = this

    // Use geolocation to get user's location and disable controls if not within
    // one mile of mower.
      _this.userPositionCallback(mowerLat, mowerLng, _this)

    // If override button is pressed, controls are enabled
    // TODO: This does not keep controls enabled after sending control or refreshing.
    //  Maybe remove this and keep the admin enable/disable button in nav drop down?
    $(window).on("geolocation-override", function() {
      _this.enableControls()
    })
  }

  userPositionCallback(mowerLat, mowerLng, _this) {
    this.getUserPosition(function (userLat, userLng) {
      // TODO: Remove after geolocation trial period.
      if (document.cookie.indexOf('companion_geolocation=') != -1) {
        if (!_this.isNearMower(mowerLat, mowerLng, userLat, userLng)) {
          $("#geolocation-notice").empty()
          $("#geolocation #controls-disabled").appendTo($("#geolocation-notice"))
        } else {
          _this.enableControls()
        }
      }
    }, mowerLat, mowerLng, _this);
  }

  getUserPosition(callback, mowerLat, mowerLng, _this) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition

    // Used in error callback to log how geolocation was declined.
    const start = Date.now();

    function success(position) {
      document.cookie = `show_location=true;path=${location.pathname}`
      var userLat = position.coords.latitude
      var userLng = position.coords.longitude
      // Update LocationMarker with user's lat/long
      $(window).trigger("new-user-location", {lat: userLat, lng: userLng})
      _this.createInternalEvent('success', {user: [userLat, userLng], mower: [mowerLat, mowerLng]})
      $("#show-location").hide()
      $('#location-notice #locating').removeClass('show')
      callback(userLat, userLng);
    }

    function error(error) {
      // If location was previously shown but location sharing is then declined,
      // don't show location notices, and add the #show-location button back.
      if (document.cookie.indexOf('show_location=true') != -1 && document.cookie.indexOf('companion_geolocation=') == -1) {
        document.cookie = `show_location=false;path=${location.pathname}`
        $('#location-notice #locating').removeClass('show')
        $("#show-location").show()
        return
      }
      console.debug(`GEOLOCATION ERROR(${error.code}): ${error.message}`);
      // Use time it takes for PERMISSION_DENIED error to be returned, to estimate
      // if geolocation is declined manually by user or automatically from phone settings.
      var isManualDecline = error.code == 1 ? (Date.now() - start) > 800 : null

      _this.createInternalEvent('error', {error: error, isManualDecline: isManualDecline})

      // TODO: Remove if statement after geolocation trial period.
      if (document.cookie.indexOf('companion_geolocation=') != -1) {
        // Remove existing notice.
        $('#geolocation-notice').empty()
        // Check if geolocation was declined or had error, add notice accordingly.
        if (error.code == 1) {
          $('#geolocation #declined').appendTo($('#geolocation-notice'))
        } else {
          $('#geolocation #error').appendTo($('#geolocation-notice'))
        }
      } else {
        $('#location-notice #locating').removeClass('show')
        if (error.code == 1) {
          $('#location-notice #declined').addClass('show')
          setTimeout(() => {$('#location-notice #declined').removeClass('show')}, 5000)
        } else {
          $('#location-notice #error').addClass('show')
          setTimeout(() => {$('#location-notice #error').removeClass('show')}, 5000);
        }
      }
    };
    // Maximum age in milliseconds of a possible cached position. 0 means cached positions can't be used.
    const options = { maximumAge: 0, enableHighAccuracy: false }

    // TODO: Remove if statement after geolocation trial period.
    if (document.cookie.indexOf('companion_geolocation=') != -1) {
      // Disable controls until location is verified
      $('#companion-controls .row .col a').addClass('disabled')
      $('#geolocation #locating').appendTo($('#geolocation-notice'))
    } else {
      $('#location-notice #locating').addClass('show')
    }
    // $('#location-notice #locating').show()
    // Get user's location using geolocation API.
    navigator.geolocation.watchPosition(success, error, options)
  }

  // Compare lat/long of user and mower and return true/false if within 1 mile or not.
  isNearMower(mowerLat, mowerLng, userLat, userLng) {
    var distance = new DistanceUtil(mowerLat, mowerLng, userLat, userLng).distanceInMiles
    return distance < GEOLOCATION_DISTANCE_MILES
  }

  // Remove geolocation notice and enable controls.
  enableControls() {
    $('#geolocation-notice').remove()
    $('#companion-controls .row .col a').removeClass('disabled')
  }

  // Creates an InternalEvent record with relevant error data
  createInternalEvent(result, options = {}) {
    if (result == 'success') {
      var details = {
        result: result,
        user_lat_lng: options.user,
        mower_lat_lng: options.mower
      }
    } else if (result == 'error') {
      var details = {
        result: result,
        error_code: options.error.code,
        error_message: options.error.message,
        manual_decline: options.isManualDecline
      }
    }
    $.ajax({
      type: 'POST',
      url: '/internal_events',
      data: {
        event: 'geolocation',
        mower_id: location.pathname.split('/')[2],
        details: details
      },
      success: function() {
        console.debug('POST request sent successfully to /internal_events')
      },
      error: function(e) {
        console.debug(`${e.responseText} - ${e.status}: ${e.statusText}`)
      }
    })
  }
}
