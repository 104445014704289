// Mower platforms with corresponding icons for displaying on companion map
const PLATFORMS_WITH_ICONS = ['intrepid', 'nova', 'beagle', 'argo']

// Logic for rendering a Google map marker representing mower location on the comp
export default class {
  constructor(latLng, iconImagePath, headingDegFromNorth = null, platform = null) {
    // An object containing 'lat' and 'lng' params
    this.latLng = latLng
    // google.maps.LatLng (more robust version of latLng)
    this.gLatLng = null
    // Path to an marker icon for the mower on the map
    this.iconImagePath = iconImagePath
    // google.maps.Marker
    this.marker = null
    // Direction mower is facing
    this.headingDegFromNorth = headingDegFromNorth
    // Type of mower
    this.platform = platform

    var _this = this

    // Renders the marker on the google map when the map has been rendered.
    $(window).on("map-rendered", function (event, googleMap) {
      _this.marker = _this.toMarker()
      _this.gLatLng = _this.toGLatLng()
      _this.marker.setMap(googleMap.gmap)
      googleMap.center()
    });
  }

  // Creates an `AdvancedMarkerElement`, sets CSS class based on platform and rotates as necessary.
  // The corresponding mower icon is set as the div background in the stylesheet.
  toMarker() {
    const marker = new google.maps.marker.AdvancedMarkerElement({
      position: this.latLng,
      content: this.content(),
    })
    this.transformMarker(marker.content)
    return marker
  }

  // Updates the latLng and marker w/the latest +latLng+ coord
  update(latLng) {
    this.latLng = latLng
    this.gLatLng = this.toGLatLng()
    this.marker.position = latLng
    this.marker.content = this.content()
    this.transformMarker(this.marker.content)
    return this
  }

  // Returns a +google.maps.LatLng+ object representing the object's lat/lng coord
  toGLatLng() {
    return new google.maps.LatLng(this.latLng)
  }

  // Returns +true+ if +latLng+ is null, empty, or has a zero value
  isEmpty() {
    return !(this.latLng || {}).lng
  }

  // Renders the marker on the +gmap+ (google.maps.Map
  render(gmap) {
    this.marker.setMap(gmap)
  }

  // Create div to use are marker content
  content() {
    const content = document.createElement("div");
    $(content).attr('id', 'mower-icon')
              .addClass(this.platform)
    return content
  }

  // Translate marker div so mower is centered on lat/long, and then rotate based on mower heading
  transformMarker(content) {
    // Only rotate if mower is reporting heading, and the icon is a platform-specific icon
    if (typeof (this.headingDegFromNorth) == 'number' && PLATFORMS_WITH_ICONS.includes(this.platform)) {
      // Translate and rotate must be in the same ruleset in order for both to apply
      content.style.transform = `translateY(50%) rotate(${this.headingDegFromNorth}deg)`
    } else {
      content.style.transform = `translateY(50%)`
    }
  }

  updateInfo(heading, platform = null) {
    this.headingDegFromNorth = heading
    if (platform && !this.platform) { this.platform = platform }
  }
}
