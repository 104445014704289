/* Logic for rendering a the mapping guide */
export default class {
  constructor(data) {
    var _this = this
    $(window).on("new-content", function(event, data) { _this.updateGuide(data) })
  }

  updateGuide(data) {
    if (data.guide_partial) {
      $('.page[data-name="Guide"]').html(data.guide_partial)
    }
  }
}
