// Logic for updating mower state based on fields in the mower status payload

// Used to set icon, color, and text for GPS status based on corresponding enum
const GPS_STATUS = {
  '0': ['bi-reception-0', '#a81414', 'Poor'],
  '2': ['bi-reception-2', '#ce7100', 'Fair'],
  '3': ['bi-reception-3', '#00cf00', 'Good'],
  '4': ['bi-reception-4', 'green', 'Excellent']
}

// Used to set color and text for state machine state based on corresponding enum
const STATE_STATUS = {
  '-1': ['pl-md-3', 'UND', 'Undefined', '#666'],
  '0': ['pl-md-3', 'RDY', 'Ready', '#006200'],
  '1': ['pl-md-3', 'MAP', 'Mapping', '#0080ef'],
  '2': ['pl-md-3', 'INIT', 'Initializing', '#00c7f0'],
  '3': ['pl-md-2', 'MOW', 'Mowing', '#00c7f0'],
  '4': ['pl-md-3', 'ERR', 'Error', '#a81414'],
  '5': ['pl-md-3', 'VAL', 'Validation', 'purple'],
  '6': ['pl-2', 'GOTO', 'Validation', '#00c7f0']
}
const READY_STATE_ENUM = 0
const GOTO_STATE_ENUM = 6

export default class MowerState {
  constructor(attributes, stateTimer) {
    let data = attributes.data
    let vehicle_status = data.vehicle_status
    this.state = data.state_machine_state
    this.isJobLoaded = !!attributes.jobId
    if (attributes.gpsStatus) { this.setGps(GPS_STATUS[attributes.gpsStatus]) }
    this.setAutoManual(vehicle_status.auto_ready)
    // TODO: Temporary handling of both old and new field name
    this.isEarthValid = data.is_earth_valid || data.is_map_corrected_valid
    this.setEarthValid(this.isEarthValid)
    if (typeof(this.state) == 'number') { this.setState(STATE_STATUS[this.state]) }
    this.setStateTimer(stateTimer, data.state_machine_state_start_time)
    this.setBooleanState(vehicle_status.blades_enabled, $('#blades-status'))
    this.setBooleanState(vehicle_status.brake_engaged, $('#brake-status'))
    // TODO: Temporary handling of both old and new field names
    let vehicle_estop_disengaged = vehicle_status.vehicle_estop_disengaged ?? !vehicle_status.vehicle_estop_triggered
    let remote_stop_disengaged = vehicle_status.remote_stop_disengaged ?? !vehicle_status.remote_estop_triggered
    this.setBooleanState(!vehicle_estop_disengaged, $('#vehicle-estop-status'))
    this.setBooleanState(!remote_stop_disengaged, $('#remote-estop-status'))
    this.setBooleanState(data.teleop_enabled, $('#teleop-status'))
    this.setBatteryLevel(attributes.batteryLevel)
    this.showMowOrCancelButton()
  }

  setGps(status) {
    $('#gps-status>.bi').removeClass('bi-reception-1, bi-reception-2 bi-reception-3 bi-reception-4')
    $('#gps-status>.bi').addClass(status[0])
    $('#gps-status>.bi').css('color', status[1])
    $('#gps-text').css('color', status[1])
    $('#gps-status-text').html(status[2])
  }

  setAutoManual(status) {
    if (status == true) {
      $('#auto-manual').css('color', 'green')
      $('#auto-manual').html('Auto')
      $('#auto-manual-status').html('Autonomous')
    } else if (status === false) {
      $('#auto-manual').css('color', '#004cda')
      $('#auto-manual').html('Man.')
      $('#auto-manual-status').html('Manual')
    }
  }

  setEarthValid(status) {
    if (status == true) {
      $('#previous-job-menu').removeClass('not-earth-valid')
      $('.not-earth-valid-icon').hide()
      $('.tab-content').html($('.tab-content').children())
    } else {
      $('#previous-job-menu').addClass('not-earth-valid')
      $('.tab-content').addClass('not-earth-valid')
      $('.not-earth-valid-icon').show()
      if ($('#previous-job-menu').hasClass('show')) {
        $('.tab-content').html($('.tab-content').children())
        $('.tab-content').prepend('Job reloading is currently unavailable. Please wait for better GPS')
      }
    }
  }

  setState(status) {
    $('#state-machine-state').removeClass('pl-2 pl-md-2 pl-md-3')
    $('#state-machine-state').addClass(status[0])
    $('#state-machine-state').html(status[1])
    $('#state-machine-state').css('color', status[3])
    $('#state-text').html(status[2])
    // Update color of circle around mower icon. On page load, wait until element is created before updating
    if ($("#mower-icon").length) {
      $("#mower-icon").css('border-color', status[3])
    } else {
      var counter = 0
      var interval = setInterval(function () {
        counter += 1
        if (counter > 15) { clearInterval(interval) }
        if ($("#mower-icon").length) {
          clearInterval(interval)
          $("#mower-icon").css('border-color', status[3])
        }
      }, 100)
    }
    if (this.state == GOTO_STATE_ENUM) {
      $('#go-to-progress').removeClass('d-none')
    } else if (!$('#go-to-progress').hasClass('d-none')) {
      $('#go-to-progress').addClass('d-none')
    }
  }

  // Create timer using setInterval() function to show time elapsed since mower entered current state machine state
  setStateTimer(stateTimer, stateStartTime) {
    if (stateTimer.startTime != stateStartTime) {
      stateTimer.startTime = stateStartTime
      clearInterval(stateTimer.interval)
      stateTimer.interval = setInterval(function () {
        let delta = Date.now() - (stateStartTime * 1000)
        $('#time-in-state').html(timeUtil.secondsToHms(delta / 1000))
      }, 1000)
    }
  }

  // Set text in mower state display to 'On' or 'Off' depending on various state booleans
  setBooleanState(state, element) {
    let text = state ? 'On' : 'Off'
    element.html(text)
  }

  setBatteryLevel(batteryLevel) {
    if (batteryLevel) {
      $('#battery-level').html(batteryLevel).parent().removeClass('d-none')
    }
  }

  // If a job is loaded, show the MOW button if in ready state, or CANCEL button if in any other state.
  // If no job is loaded, do not show either button.
  showMowOrCancelButton() {
    if (!this.isJobLoaded || $('.recording-progress').is(':visible')) {
      this.hideMowOrCancelButton()
    } else if (this.state == READY_STATE_ENUM) {
      $("#cancel-button").addClass('d-none')
      $("#mow-button").removeClass('d-none')
    } else {
      $("#cancel-button").removeClass('d-none')
      $("#mow-button").addClass('d-none')
    }
  }

  hideMowOrCancelButton() {
    $("#mow-cancel-buttons").children().addClass('d-none')
  }
}
