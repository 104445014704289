/*
 * Logic for rendering the "no connection" notice that is displayed when expected data hasn't come through
 * in a while.
 */

// If data isn't received within this duration, the no connection notice is displayed.
const THRESHOLD_IN_SECONDS = 90

export default class {
  constructor(state) {
    // Timer for displaying no connection notice.
    this.timer = null;
    // Displayed when data should have been received and displayed but hasn't
    this.$notice = $("#no_connection_notice")
    // Access overall state of the companion app
    this.state = state
    // Set timer to display no connection notice
    this.setTimer()

    var _this = this;

    $(window).on("no-connection", function(event) { _this.show()})
    // Don't show this notice if the large offline notice is already displayed
    $(window).on("show-offline", function(event) { _this.disableDisplay() })
    $(window).on("hide-offline", function(event) { _this.permitDisplay() })
    $(window).on("reset-connection-monitor", function(event) { _this.reset() })
  }

  disableDisplay() {
    this.$notice.addClass("vis-hidden")
  }

  permitDisplay() {
    this.$notice.removeClass("vis-hidden")
  }

  setTimer() {
    console.debug("Setting no connection timer.")
    this.timer = setTimeout(function() { $(window).trigger("no-connection")}, THRESHOLD_IN_SECONDS*1000)
  }

  show() {
    console.debug("Showing no connection notice.")
    this.$notice.addClass('show')
  }

  reset() {
    console.debug("Resetting no connection notice.")
    this.$notice.removeClass('show')
    clearTimeout(this.timer)
    this.setTimer()
  }
}
