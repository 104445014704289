require("moment-timezone")
import moment from "moment"

/*
 * Encapsulates view-related javascript handling of time
 * (setting a timezone, rendering JS dates in the tz, formatting JS Dates).
 *
 * When initialized, the user's timezone name (a string) is retrieved and stored in
 * a "time_zone" cookie. This cookie value can be accesssed for server-side rendering.
 *
 * Usage for client-side rendering:
 * - Add a "data-js-date" attribute to an element with an iso8601-formatted string.
 *   Example: `<span data-js-date="<%= status.created_at.iso8601 %>"></span>`
 * - Trigger a "apply-time-formatting" event, which calls the `applyAll()` function and
 *   converts the string into a moment object, applies the user's timezone, and formats the time,
 *   rendering it within the element.
 */

// Name of the cookie to store the user timezone name (String).
const COOKIE_NAME = 'time_zone'

// Name of the data attribute used to apply dates
const DATA_JS_DATE = 'data-js-date'

export default class {
  constructor() {
    // Get the user time zone name
    this.name = Intl.DateTimeFormat().resolvedOptions().timeZone

    this.setCookie()

    var _this = this
    // apply to elements when turbolinks are loaded
    $(window).on("turbolinks:load", function(event) { _this.applyAll()})
    // listen for an event to apply time formatting to HTML elements
    $(window).on("apply-time-formatting", function(event) { _this.applyAll()})
  }

  // Store the timezone name in a cookie for 1 year
  setCookie() {
    document.cookie =`${COOKIE_NAME}=`+ this.name +'; expires='+ this.cookieExpireAt() +'; path=/' + '; SameSite=Strict';
    console.log("Storing timezone in cookie=",this.name)
  }

  // 1 year from now
  cookieExpireAt() {
    // https://stackoverflow.com/questions/8609261/how-to-determine-one-year-from-now-in-javascript
    return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  }

  /*
   * Returns a moment object representation of a date string in iso8601 format
   * within the user's timezone.
   *
   * If the tz is invalid, the moment object in the original timezone is returned.
   */
  inZone(date_iso8601) {
    let momentDate = this.toMoment(date_iso8601)
    return momentDate.tz(this.name)
  }

  // Converts an iso8601-formated string into a moment object.
  toMoment(date_iso8601) {
    return moment(date_iso8601)
  }

  // Renders the time for all $elements().
  applyAll() {
    console.log("applying timeformatting w/zone=",this.name,"# of elements=",this.$elements().length)
    var _this = this;
    this.$elements().each(function(i, ele) {
      let $ele = $(ele)
      _this.applyTo($ele)
    })
  }

  /*
   * Given a jQuery element +$ele+ with a "data-js-date" attribute set to a iso8601 string,
   * sets that element's HTML to a formatted string in the current timezone.
   *
   * If an exception occurs in date handling, the raw iso8601 string is used.
   */
  applyTo($ele) {
    let date_iso8601 = $ele.data("js-date")
    let formattedDateString
    var _this = this
    try {
      formattedDateString = _this.inZone(date_iso8601).format('h:mm:ss a')
    } catch(err) {
      console.error(err)
      Sentry.captureException(err);
      formattedDateString = date_iso8601
    }
    $ele.html(formattedDateString)
  }

  // Returns an array of elements that should have a JS date formatting applied.
  $elements() {
    return $(`*[${DATA_JS_DATE}]`)
  }

  // Convert seconds to HH:MM:SS format for front-end display
  secondsToHms(seconds) {
    return new Date(seconds * 1000).toISOString().slice(11, 19)
  }
}
