// Logic for rendering user location GPS coordinates as a google map marker if location is enabled for user
export default class {
  constructor(latLng, iconImagePath) {
    // An object containing 'lat' and 'lng' params
    this.latLng = latLng
    // Path for user icon gif
    this.iconImagePath = iconImagePath
    // google.maps.Marker
    this.marker = null

    var _this = this

    // Initialize the marker when the google js library has been loaded
    $(window).on("gmap-script-ready", function (event) {
      _this.marker = _this.toMarker()
    })

    // Renders the marker on the google map when the map has been rendered
    $(window).on("map-rendered", function (event, googleMap) {
      _this.marker.setMap(googleMap.gmap)
    });
  }

  // Returns a google.maps.Marker object representing the user's location
  toMarker() {
    var marker = new google.maps.Marker({
      position: this.latLng,
      icon: { url: this.iconImagePath, scaledSize: new google.maps.Size(30, 30) },
      scale: 20,
    })
    return marker
  }

  // Updates the marker with the latest user location
  update(latLng) {
    this.marker.setPosition(latLng)
  }
}
