// For up/down arrows, see https://github.com/joequery/Stupid-Table-Plugin/blob/master/examples/complex.html
function sortTables() {
  $("table.sort").each(function(i, table) {
    var $table = $(table).stupidtable()
    // Only apply sorting if not already applied. Will be applied already if using back button.
    // Using data-sort-onload would re-sort when the back button is used.
    if (!$table.find(".arrow").length) {
      var $th_to_sort = $table.find("thead th").eq(0);
      $th_to_sort.stupidsort();
    }

    $table.on("aftertablesort", function (event, data) {
      var th = $(this).find("th");
      th.find(".arrow").remove();
      var dir = $.fn.stupidtable.dir;

      var arrow = data.direction === dir.ASC ? "&uarr;" : "&darr;";
      var th_with_arrow = th.eq(data.column)
      if (!th_with_arrow.length) { th_with_arrow = th.eq(data.column-1)}
      th_with_arrow.append('<span class="arrow mr-2 mt-1 small">' + arrow +'</span>');
    })
  })
}

export default sortTables
