import PreviousJob from "custom/companion/previous_job"

// Logic for interacting with recent and favorite jobs, opening info windows, favoriting jobs, centering on jobs, etc.

// The Google Map zoom setting to use when just displaying the mower.
const ZOOM_LEVEL = 18

// The number of jobs to display in each group for jobGroups().
const JOB_GROUP_SIZE = 5

export default class {
  constructor(recentJobs, favoriteJobs, isAdmin, jobIdToEdit, duplicateButtonIcon) {
    // The google map object.
    this.gmap = null
    // Create array of PreviousJob objects from the attributes of recent Job records in the database.
    this.recentJobs = recentJobs.map((job) => {
      // Label is used to display on job markers and job buttons. Label is either the name of the job, or the date created in the db.
      let label = job.name || this.formatDate(job.created_at)
      return new PreviousJob(job, label)
    })
    // Create array of PreviousJob objects from the attributes of favorite Job records in the database.
    this.favoriteJobs = favoriteJobs.map((job) => {
      // If favorite job is also a recent job, use recent job object
      let recentJob = this.jobFromJobId(job.jobId)
      if (recentJob) {
        return recentJob
      } else {
        // Label is used to display on job markers and job buttons. Label is either the name of the job, or the date created in the db.
        let label = job.name || this.formatDate(job.created_at)
        return new PreviousJob(job, label)
      }
    })
    // Only one info window is opened at a time.
    this.infoWindow = null
    // The previous jobMarker object. Referenced to remove active status.
    this.previousMarker = null
    // Checks if user is admin. (Not currently used)
    this.isAdmin = isAdmin
    // Find the related job object for the job id that is currently being edited.
    this.jobIdToEdit = jobIdToEdit
    if (this.jobIdToEdit) { this.jobToEdit = this.jobFromJobId(this.jobIdToEdit) }
    this.duplicateButtonIcon = duplicateButtonIcon

    var _this = this;

    // Triggered when a job is loaded successfully.
    $(window).on("job-loaded", (event) => {
      _this.setPolygonOptions()
      // If the current job is out of sync and has an infowinow open, close it.
      if (map.currentJob?.infoWindow) { map.currentJob.infoWindow.close() }
    })

    // Triggered when Google Maps script is loaded.
    $(window).on("gmap-script-ready", () => {
      // Triggered when the map is rendered.
      $(window).on("map-rendered", () => {
        _this.gmap = map.gmap // map is defined in the companion layout.
        // When gmap bounds change, display message if no recent/favorite jobs exist in the area.
        // When previous job tabs are clicked, show or hide jobs, and center accordingly.
        // Move map, alerts, and stripe angle along with the jobs.
        $("#recent-pill, #favorite-pill").on("click", (event) => {
          if (_this.isMenuOpen() && $(event.target).hasClass('active')) {
            _this.closeJobMenu()
          } else if (_this.isMenuOpen()) {
            _this.showJobs(event.target.id.split('-')[0])
          } else {
            companion.mappingMenu?.hideGoTo()
            $('#mapping-menu').addClass('d-none')
            _this.openJobMenu(event.target.id.split('-')[0])
          }
          if (!$(event.target).hasClass('active')) {
            _this.toggleJobTabs()
          }
        })

        $('#mapping-menu-button').on('click', () => {
          if (_this.isMenuOpen()) {
            _this.closeJobMenu()
          }
        })

        // When the map is clicked, close open info window and deselect job. Center on job/s.
        google.maps.event.addListener(this.gmap, "click", () => {
          _this.closeInfoWindow()
        })
        this.setPolygonOptions()
        this.populateJobMenu()
        // If the edit form is opened, show and center the relevant job.
        if (this.jobToEdit) {
          location.href.includes('job_edit') ? this.showJobToEdit() : map.center()
        }
        $('#close-edit-form').on('click', () => { _this.closeEditForm(true) })
      })
    })

    // Post to favorite endpoint of jobs controller when favorite star is clicked. Update corresponding PreviousJobs.
    $(window).on('favorite-star', (event, jobId) => {
      $.ajax({
        type: 'POST',
        url: `/jobs/${jobId}/favorite`,
        data: { mower_id: location.pathname.split('/')[2] },
        success: (jobInfo) => {
          const recentJob = _this.recentJobs.find((job) => job.jobId == jobId)
          if (jobInfo.favorite) {
            $('#info-window-star').removeClass('bi-star').addClass('bi-star-fill')
            recentJob.jobMarker.starIcon = "bi bi-star-fill"
            if (!_this.favoriteJobs.includes(recentJob)) { _this.favoriteJobs.push(recentJob) }
          } else {
            const favoriteJob = _this.favoriteJobs.find((job) => job.jobId == jobId)
            favoriteJob.jobMarker.starIcon = "bi bi-star"
            $('#info-window-star').removeClass('bi-star-fill').addClass('bi-star')
            _this.favoriteJobs = _this.favoriteJobs.filter((job) => { return job != favoriteJob})

            if (_this.activeTab() == 'favorite') {
              _this.infoWindow.close()
              favoriteJob.hide()
            }
            $(`#favorite-button-${favoriteJob.jobId}`).remove()
          }
          this.populateJobMenu()
          if ($('#favorite-jobs-carousel .carousel-inner').children().children().is(':empty')) {
            $("#favorite-jobs-carousel .carousel-inner").append('<div id="no-favorite" class="text-center w-100 px-3" style="font-size:15px">Select a job on the <b class="text-success">Recent Jobs</b> tab, and press the <i class="bi bi-star text-success" style="font-size:24px;"></i> to add to favorites.</div>')
          }
        },

        error: (e) => {
          console.debug(`${e.responseText} - ${e.status}: ${e.statusText}`)
        }
      })
    })
  }

  // Close current info window and deselect job.
  closeInfoWindow() {
    $(window).trigger('close-info-window')
    if (this.infoWindow) {
      this.infoWindow.close()
      $(`#${this.activeTab()}-button-${this.previousMarker?.job.jobId}`).removeClass('active')
      $(`#marker-${this.previousMarker?.job.jobId}`).removeClass('active')
      this.setPolygonOptions()
    }
  }

  // Create and render google info window for jobMarker.
  openInfoWindow(jobMarker) {
    let isCurrentJob = jobMarker.job.jobId == map.currentJob?.jobId
    this.infoWindow = new google.maps.InfoWindow({
      content: this.infoWindowContent(jobMarker, isCurrentJob),
      marker: jobMarker.marker,
      disableAutoPan: true
    })
    this.infoWindow.open({
      anchor: jobMarker.marker,
      map: this.gmap,
    })
    if (isCurrentJob) {
      jobMarker.marker.polygon.setOptions({
        strokeColor: '#40ff00',
        strokeWeight: 4,
        strokeOpacity: 0.9,
        fillColor: '#026101',
        zIndex: 100
      })
    } else {
      jobMarker.marker.polygon.setOptions({
        strokeColor: 'rgb(2, 123, 255)',
        strokeWeight: 4,
        strokeOpacity: 0.9,
        fillColor: '#2e2e2eff',
        zIndex: 100
      })
    }

    this.previousMarker = jobMarker

    $(`#${this.activeTab()}-button-${jobMarker.job.jobId}`).addClass('active')
    google.maps.event.addListener(this.infoWindow, 'closeclick', () => {
      this.closeInfoWindow()
    })
  }

  // For current job, set polygon color to green. For other jobs, set polygons to clear.
  setPolygonOptions() {
    this.recentJobs.forEach(job => { job.jobId != map.currentJob.jobId ? job.setGray() : job.setClear() })
    this.favoriteJobs.forEach(job => { job.jobId != map.currentJob.jobId ? job.setGray() : job.setClear() })
  }

  // Hides all previous jobs.
  hideJobs() {
    $('#previous-job-menu').removeClass('show')
    $('.tab-content').html($('.tab-content').children())
    this.recentJobs.forEach((job) => { job.hide() })
    this.favoriteJobs.forEach((job) => { job.hide() })
    map.center()
  }

  // Shows all previous jobs.
  showJobs(target = null) {
    // Add note to job menu if GPS is not earth valid
    if (!companion.mowerState.isEarthValid) {
      $('.tab-content').html($('.tab-content').children())
      $('.tab-content').prepend('Job reloading is currently unavailable. Please wait for better GPS')
    }
    this.toggleJobGroups(target)
    if (map.currentJob.infoWindow) { map.currentJob.closeInfoWindow() }
    if (map.currentJob.marker) { map.currentJob.hideMarker() }
  }

  // Centers map on all visible previous jobs, or mower and current job.
  centerOnJobs(jobs) {
    let bounds = new google.maps.LatLngBounds()
    jobs.forEach((job) => {
      bounds.union(job.polygon.getBounds())
    })
    bounds.extend(map.status.gLatLng)
    if (map.currentJob.jobId) {
      bounds.union(map.currentJob.polygon.getBounds())
    }
    this.gmap.fitBounds(bounds)
    if (!map.currentJob.jobId && !jobs) {
      this.gmap.setZoom(ZOOM_LEVEL)
    }
  }

  // Close the previous jobs menu, hide any visible previous jobs
  closeJobMenu() {
    this.hideJobs()
    this.closeInfoWindow()
    map.currentJob.showMarker()
    $('.page[data-name="Map"] .alert_notice').removeClass("with-jobs")
    $("#map").removeClass("shrink")
    map.currentJob.showKeepOutPolygons()
    map.currentJob.showRecordedPathIcons()
  }

  // Open the previous jobs menu, and show relevant jobs
  openJobMenu(target = null) {
    this.showJobs(target)
    this.closeInfoWindow()
    $('#previous-job-menu').addClass('show')
    $('.page[data-name="Map"] .alert_notice').addClass("with-jobs")
    $("#map").addClass("shrink")
    this.addSlideListener('recent')
    this.addSlideListener('favorite')
    map.currentJob.hideKeepOutPolygons()
    map.currentJob.hideRecordedPathIcons()
  }

  populateJobMenu() {
    let types = ['recent', 'favorite']
    types.forEach((type, i) => {
      this.addMarkerButtons((i < 1 ? this.recentJobs : this.favoriteJobs), type)
      this.addCarouselControls(this.carouselClasses(type))
    })
    this.addSlideListener('recent')
    this.addSlideListener('favorite')
  }

  addSlideListener(type) {
    var _this = this
    $(`#${type}-jobs-carousel.carousel`).off('slid.bs.carousel')
    $(`#${type}-jobs-carousel.carousel`).on('slid.bs.carousel', '', (event) => {
      _this.updateCarouselControls(_this.carouselClasses(type))
    })
  }

  // Show recent/favorite jobs depending on which tab is clicked
  toggleJobTabs() {
    if ($("#favorite-pill-star").hasClass("bi-star")) {
      $("#favorite-pill-star").removeClass("bi-star").addClass("bi-star-fill text-success")
    } else {
      $("#favorite-pill-star").removeClass("bi-star-fill text-success").addClass("bi-star")
    }
  }

  // Split jobs into groups of JOB_GROUP_SIZE.
  jobGroups(jobs) {
    let jobGroups = jobs.reduce((result, job, i) => {
      const groupIndex = Math.floor(i / JOB_GROUP_SIZE)
      if (!result[groupIndex]) { result[groupIndex] = [] }
      result[groupIndex].push(job)
      return result
    }, [])
    return jobGroups
  }

  addMarkerButtons(jobs, type) {
    if (jobs.length) {
      $(`#${type}-jobs-carousel .carousel-inner`).empty()
      let jobGroups = this.jobGroups(jobs)
      for (var i = 0; i < jobGroups.length; i++) {
        let active = i == 0 ? " active" : ""
        $(`#${type}-jobs-carousel .carousel-inner`).append(`<div class="carousel-item${active}"> <div id="${type}-buttons-${i}" class="d-flex align-content-stretch w-100"></div></div>`)
      }
      jobGroups.forEach((jobs, i) => {
        jobs.forEach((job) => {
          job.jobMarker.addMarkerButton(`#${type}-buttons-${i}`, type, job.isRecordAndRepeat)
        })
      })
    }
  }

  // Toggle visibility of jobs in carousel based on which slide is active.
  toggleJobGroups(target) {
    this.inactiveJobs(target).forEach((job) => { job.hide() })
    let activeIndex = $(`#${this.activeTab(target)}-jobs-carousel .carousel-item.active`).index()
    let jobs = this.jobGroups(this.activeJobs(target))
    if (jobs.length) {
      jobs.forEach ((jobs, i) => {
        if (i == activeIndex) {
          this.centerOnJobs(jobs)
          jobs.forEach((job) => { job.show() })
        } else {
          jobs.forEach((job) => { job.hide() })
        }
      })
    } else {
      map.center()
    }
  }

  // Show or hide directional arrows for carousel based on number of slides and which slide is active.
  addCarouselControls(carousel) {
    // Hide controls if there is only one carousel slide of jobs. If >1 slide, hide left control for first slide.
    if ($(`${carousel.inner} .carousel-item:first`).is($(`${carousel.inner} .carousel-item:last`)) || ($(`#${carousel.type}-jobs-carousel .carousel-inner`).is(':empty') && 1)) {
      $(`${carousel.prev}, ${carousel.next}`).hide()
    } else {
      if (carousel.type == 'favorite') {
        if ($(`#favorite-jobs-carousel .carousel-inner .carousel-item`).length > 1) {
          $(carousel.next).show()
        } else if ($("#no-favorite").length) {
          $(carousel.next).hide()
        }
      } else if (carousel.type == 'recent' && $('#no-recent').length) {
        $(carousel.next).hide()
      }
      $(carousel.prev).hide()
    }
  }

  // Show/hide controls depending on which slide is active.
  updateCarouselControls(carousel) {
    this.toggleJobGroups(null)
    if ($(`${carousel.inner} .carousel-item:first`).hasClass('active')) {
      $(carousel.prev).hide()
      $(carousel.next).show()
    } else if ($(`${carousel.inner} .carousel-item:last`).hasClass('active')) {
      $(carousel.next).hide()
      $(carousel.prev).show()
    } else {
      $(`${carousel.prev}, ${carousel.next}`).show()
    }
  }

  carouselClasses(type) {
    let carousel = `#${type}-jobs-carousel`
    return {
      carousel: carousel,
      prev: `${carousel} .carousel-control-prev`,
      next: `${carousel} .carousel-control-next`,
      inner: `${carousel} .carousel-inner`,
      type: type
    }
  }

  // When job edit page opens, center and show the job.
  showJobToEdit() {
    if (map.currentJob.jobId) {
      if (map.currentJob.infoWindow) { map.currentJob.closeInfoWindow() }
      if (map.currentJob.marker) { map.currentJob.hideMarker() }
    }
    this.jobToEdit.center()
    // Pan the map up 100px to account for job edit menu. Wait 50ms to account for other centering functions.
    setTimeout(() => { map.gmap.panBy(0, 100) }, 50)
    if (this.jobToEdit.jobId == map.currentJob.jobId) { map.currentJob.hideKeepOutPolygons() }
  }

  hideJobToEdit() {
    this.jobIdToEdit = null
    this.jobToEdit.hide()
    companion.stripeAngleOverlay?.setMap(null)
  }

  formatDate(date) {
    return new Date(date).toLocaleString("en-US", { month: 'numeric', day: 'numeric', year: '2-digit', hour: 'numeric', minute: 'numeric' }).replace(',', '')
  }

  // When the X on the edit form is clicked. Hide the form and stripe angle overlay, show the current job, and update the URL.
  closeEditForm(noUpdate = null) {
    $('#job-edit-form-companion').hide()
    this.jobToEdit.hide()
    map.currentJob?.show()
    window.history.replaceState({}, document.title, `/companion/${location.pathname.split('/')[2]}`)
    companion.showMowingProgress()
    this.jobToEdit = null
    companion.stripeAngleOverlay?.setMap(null)
    if (noUpdate) { $(window).trigger('show-stripe-overlay') }
    companion.toggleStripePlan()
  }

  // Find a job object for a given job id from favorite or recent jobs
  jobFromJobId(jobId) {
    return this.recentJobs.find((job) => job.jobId == jobId) || this.favoriteJobs?.find((job) => job.jobId == jobId)
  }

  isMenuOpen() {
    return $('#previous-job-menu').hasClass('show')
  }

  // The previous job tab that is either currently active, or was just clicked
  activeTab(target = null) {
    if (target == 'recent' || (!target && $("#recent-pill").hasClass("active"))) {
      return 'recent'
    } else {
      return 'favorite'
    }
  }

  // The set of previous jobs associated with the active previous job tab
  activeJobs(target = null) {
    if (target == 'recent' || (!target && this.activeTab() == 'recent')) {
      return this.recentJobs
    } else {
      return this.favoriteJobs
    }
  }

  // The set of previous jobs not associated with the active previous job tab
  inactiveJobs(target = null) {
    if (target == 'recent' || (!target && this.activeTab() == 'recent')) {
      return this.favoriteJobs
    } else {
      return this.recentJobs
    }
  }

  // If the job is earth valid, create new `PreviousJob`, add to `recentJobs` array, and add button to jobs menu
  addNewJob(jobInfo) {
    if (jobInfo.isEarthValid) {
      let label = jobInfo.name || this.formatDate(jobInfo.created_at)
      let job = new PreviousJob(jobInfo, label)
      job.renderNew()
      this.recentJobs.unshift(job)
      this.populateJobMenu()
      if (this.isMenuOpen() && this.activeTab() == 'recent') {
        job.show()
      }
    }
  }

  addOrUpdateJob(jobInfo) {
    let previousJob = this.jobFromJobId(jobInfo.jobId)
    if (!previousJob) {
      this.addNewJob(jobInfo)
    } else {
      if (previousJob.keepOutZonePolygons) {
        previousJob.hideKeepOutPolygons()
        previousJob.keepOutZones = jobInfo.keepOutZones
        previousJob.render(map)
      }
      if (jobInfo.name) { previousJob.updateName(jobInfo.name) }
      if (!this.isMenuOpen()) { previousJob.hide() }
    }
    this.jobToEdit?.render(map)
    this.jobToEdit?.hideMarker()
  }

  // Button for loading job on map for info window. Includes job edit button, and repeat symbol if applicable.
  // TODO: Move html to js.erb.html templates or hidden partials for easier reading
  infoWindowContent(jobMarker, isCurrentJob) {
    if (jobMarker.job.jobId == map.currentJob.jobId) {
      if (jobMarker.job.isSyncedWithMower()) {
        return "<div class='mb-1' style='max-width:140px'>This job is loaded on the mower</div>" + this.infoWindowContentBase(jobMarker, isCurrentJob)
      } else {
        return `<div class="container p-0" style="max-width: 280px;">
                  <div class="row no-gutters">
                    <div class="col">
                      <b class='text-center mr-2'>
                      Recent job edits have not been loaded to the mower. Load job before pressing MOW.
                      </b>
                    </div>
                    <div class="col-5">
                      <button type='submit' id='info-window' class='btn btn-primary px-2 mr-0 ml-2' onclick='$(window).trigger("load-map-${jobMarker.job.jobId}")'>
                        Load Job
                      </button>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-between no-gutters">
                    <button class="btn p-0 m-0">
                      <i id="info-window-star" class="${jobMarker.starIcon} text-success" onclick='$(window).trigger("favorite-star", "${jobMarker.job.jobId}")' style="font-size:40px;"></i>
                    </button>
                    <a data-confirm="Are you sure you want to delete this job?" class="btn p-0 pt-2" data-method="delete" href="/jobs/${jobMarker.job.recordId}?mower_id=${location.pathname.split('/')[2]}&is_current_job=${isCurrentJob}">
                      <i class="bi bi-trash ml-1" style="font-size:34px;color:#dc3545"></i>
                    </a>
                    <form style="display:inline" action="/companion/${location.pathname.split('/')[2]}/job_edit" accept-charset="UTF-8" method="get">
                      <input type="hidden" id="job_id" name="job_id" value="${jobMarker.job.jobId}">
                        <button class="btn p-0 ml-1 pt-2" type="submit">
                          <i id="job-edit-button" class="bi bi-pencil-square" style="font-size:34px;color:#666"></i>
                        </button>
                    </form>
                    <div>
                      <a data-confirm="Are you sure you want to duplicate this job?" class="btn p-0 pt-3" data-method="post" href="/jobs/${jobMarker.job.recordId}/duplicate?mower_id=${location.pathname.split('/')[2]}">
                        <img src=${this.duplicateButtonIcon} style='width:34px;'>
                      </a>
                    </div>
                  </div>
                </div>`
      }
    } else {
      return this.infoWindowContentBase(jobMarker, isCurrentJob)
    }
  }

  infoWindowContentBase(jobMarker, isCurrentJob) {
    let repeats = jobMarker.job.repeats ? '<i class="bi bi-arrow-repeat" style="font-size:14px"></i>' : ''
    return `<div class="d-flex flex-column">
      <div class="d-flex flex-row" style="height:40px">
        <div>
          <button type='submit' id='info-window' class='btn btn-primary' onclick='$(window).trigger("load-map-${jobMarker.job.jobId}")'>
            Load Job ${repeats}
          </button>
        </div>
        <div>
          <button class="btn p-0">
            <i id="info-window-star" class="${jobMarker.starIcon} text-success position-relative ml-1" onclick='$(window).trigger("favorite-star", "${jobMarker.job.jobId}")' style="font-size:40px;top:-10px"></i>
          </button>
        </div>
      </div>
    </div>
    <div>
      <div class="d-flex flex-row justify-content-between">
        <div>
          <a data-confirm="Are you sure you want to delete this job?" class="btn p-0" data-method="delete" href="/jobs/${jobMarker.job.recordId}?mower_id=${location.pathname.split('/')[2]}&is_current_job=${isCurrentJob}">
            <i class="bi bi-trash" style="font-size:34px;color:#dc3545"></i>
          </a>
        </div>
        <div>
          <form style="display:inline" action="/companion/${location.pathname.split('/')[2]}/job_edit" accept-charset="UTF-8" method="get">
            <input type="hidden" id="job_id" name="job_id" value="${jobMarker.job.jobId}">
            <button class="btn p-0" type="submit">
              <i id="job-edit-button" class="bi bi-pencil-square" style="font-size:34px;color:#666"></i>
            </button>
          </form>
        </div>
        <div>
          <a data-confirm="Are you sure you want to duplicate this job?" class="btn p-0 pt-2" data-method="post" href="/jobs/${jobMarker.job.recordId}/duplicate?mower_id=${location.pathname.split('/')[2]}">
            <img src=${this.duplicateButtonIcon} style='width:34px;'>
          </a>
        </div>
      </div>
    </div>`
  }
}
