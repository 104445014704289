import MowingProgress from "custom/companion/mowing_progress"
import MowerState from "custom/companion/mower_state"
import MappingMenu from "custom/companion/mapping_menu"
import Job from "custom/companion/job"

/* Logic for rendering the Companion UI.
 *
 * Listens for events on the ActionCable websocket to render status/alert updates and
 * toggles error state and map views.
 *
 * Events:
 *
 * "reset-connection-monitor" - Triggered when the connection monitor should be reset.
 * "new-mower-status" - fn(gpsCoords) - Triggered when a new mower status has been received.
 * "new-mower-map" - fn({paths: [..gpsCoords]}) - Triggered when a new mower boundary map is received.
 * "apply-time-formatting" - Triggered when HTML elements w/timestamps have been updated.
 * "show-offline" - Triggered when the offline notice should be displayed.
 * "hide-offline" - Triggered when the offline notice should be hidden.
 * "show-map" - Triggered when the Google Map container element is shown.
 */

export default class {
  constructor(data) {
    // Whether the companion app is operating under debug mode.
    this.isDebugMode = data.debug_mode;
    // The map container. Google Map is drawn within this.
    // The companion/google_map class also references this.
    this.$map = $("#map")
    // Displayed instead of the map when the mower is offline.
    this.$offlineNotice = $("#offline_notice")
    // Displayed instead when mover isn't reporting GPS.
    this.$gpsNotice = $("#gps_notice")
    // Badge that displays count of active alerts
    this.$activeAlertsBadge = $(".menu_item[data-name='Alerts'] .badge")
    // User location found from geolocation API. Used for showing location and
    // disabling controls if not near mower.
    this.userLatLng = null
    // Initalize Geolocation to get users location and disable controls if far from mower
    this.geolocation = null
    // Notice is displayed on map page if mower update is available.
    this.updateReady = data.update_ready
    // MowingProgress object displaying mowed areas polygon from mower status
    this.mowingProgress = null
    // Array(s) of lat/lng objects from mower status
    this.mowingProgressFromStatus = null
    // MowerState object containing logic for updating mower state display
    this.mowerState = null
    // Contains the start time of the current mower state, and the interval id returned from setInterval()
    this.stateTimer = { startTime: null, interval: null}
    // Object containing mower plan path and stripe angle
    this.plan = null
    // Google maps polyline object used to display plan on map
    this.planPolyline = null
    // Stripe angle overlay object representing stripe angle from job or plan payload
    this.stripeAngleOverlay = null
    // Logic for displaying record and repeat, keep-out, and go-to features
    this.mappingMenu = new MappingMenu()
    this.isJobInProgress = null
    this.hasLatestSoftwareVersion = data.has_latest_software_version
    this.notices = data.notices

    var _this = this;

    // Triggered when the ActionCable websocket receives new content.
    $(window).on("new-content", function(event, data) {
      _this.updateState(data)})
    // Visit the alerts page when the alert notice is clicked
    $('.page[data-name="Map"]').on("click", ".alert_notice", function(event) {
      $(window).trigger({
        type: "visit",
        page: 'Alerts'
      })
    })
    // Triggered when the Google Map is rendered with a marker.
    $(window).on("map-rendered", function(event, googleMap) {
      _this.toggleMapDisplay(googleMap.status.latLng)
      _this.setVersionUpdateDescription()
      // Show plan or stripe overlay depending on the map zoom level
      googleMap.gmap.addListener('zoom_changed', () => {
        _this.toggleStripePlan()
      })
      if (_this.notices) {
        $('#notices-modal').modal('show')
        $('#notices-modal .modal-body').html(_this.notices)
      }
    })
    $(window).on("map-updated", function(event, googleMap) { _this.toggleMapDisplay(googleMap.status.latLng) })
    $(window).on("new-data", function(event, data) { _this.updateData(data)})
    this.updateData(data)

    // TODO: Update logic after geolocation trial period
    $("#show-location").on("confirm:complete", function() {
      _this.geolocation = new Geolocation(data.mower_lat, data.mower_lng)
    })
    if (document.cookie.indexOf('companion_geolocation=') != -1 || document.cookie.indexOf('show_location=true') != -1) {
      _this.geolocation = new Geolocation(data.mower_lat, data.mower_lng)
    }
  }

  /*
   * Returns +true+ if the map page is the currently active page.
   */
  onMapPage() {
    return !!$(".page.active[data-name='Map']").length
  }

  updateData(data) {
    console.debug("data", data)

    if (data.alert_report) {
      $(window).trigger("new-content", data.alert_report)
    }
    if (data.status) {
      $(window).trigger("new-content", data.status)
    }
    if (data.job) {
      $(window).trigger("new-content", data.job)
    }
    if (data.plan) {
      $(window).trigger("new-content", data.plan)
    }
  }

  /*
   * Displays elements marked as .debug when debug mode is enabled.
   */
  showDebugInfo() {
    if (!this.isDebugMode) return;
    console.debug("isDebugMode=",this.isDebugMode, "Showing debug info.");
    $(".debug").removeClass('d-none');
  }

  /*
   * Updates the state of the companion ui using the provided +content+.
   * Content is either an 'alert' or 'status'.
   *
   * See MowerStatus#to_companion and MowerAlertReport#to_companion for the structure of the content object.
   */
  updateState(content) {
    if (!content) {
      console.log('Received empty content')
      return
    }
    if (content.type == 'alert') {
      console.log("Received ALERT. content=", content)
      // Pushes the previous alert down off the bottom of the container.
      // The previous alert isn't removed immediately as the animiation would not trigger. Instead,
      // it is removed when the next alert is rendered.
      // See https://stackoverflow.com/questions/24148403/trigger-css-transition-on-appended-element
      var $prevAlert = $('.page[data-name="Map"] .alert_notice')
      var $prevAlertGuide = $('.page[data-name="Guide"] .alert_notice')
      $prevAlert.removeClass("slide_up")
      $prevAlertGuide.removeClass("slide_up")
      if (content.partial) {
        $prevAlert.remove()
        $prevAlertGuide.remove()
        $(content.partial).appendTo($('.page[data-name="Map"]'))
        $(content.partial).appendTo($('.page[data-name="Guide"]'))
      }
      $('.page[data-name="Alerts"]').html(content.partial_list)
      // Update the active alerts count badge
      var activeAlertsCount = content.alert_count
      if (activeAlertsCount) {
        this.$activeAlertsBadge.show().html(activeAlertsCount)
      } else {
        this.$activeAlertsBadge.html(null).hide()
      }
      // Move alert notice up if previous jobs menu is open
      if ($('#previous-job-menu').hasClass('show')) {
        $('.page[data-name="Map"] .alert_notice').addClass("with-jobs")
      }
      if (content.custom_alert_messages.length) {
        $('#custom-alert').removeClass('d-none')
        $('#custom-alert #alert-message').html(content.custom_alert_messages.join('<hr>'))
        setTimeout(function() {
          $('#custom-alert').addClass('d-none')
        }, 10000)
      }
    } else if (content.type == 'status') {
      $(window).trigger("reset-connection-monitor")
      $(window).trigger("apply-time-formatting")
      console.log("received STATUS. content=", content)
      let attributes = content.attributes
      this.mowingProgressFromStatus = attributes.mowingProgress ? attributes.mowingProgress : null
      this.isJobInProgress = attributes.isJobInProgress
      if (map.status) {
        map.status.updateInfo(attributes.headingDegFromNorth, attributes.platform)
      }
      $(window).trigger("new-mower-status", attributes.gps)
      $('.page[data-name="Map"] #completion-status').remove()
      $('#map-buttons').prepend(content.partial)
      // If mower loses service temporarily and appears offline, this re-renders the current job when back online
      if (!map.currentJob?.jobId && attributes.jobId && typeof (previousJobs) != 'undefined') {
        map.updateCurrentJob(attributes.jobId)
        $(window).trigger('new-job', map)
      }
      // Create new MowerState object to update mower state display on map
      if (attributes.data?.task_state?.job_state) {
        this.mowerState = new MowerState(attributes, this.stateTimer)
      }
      if (!attributes.data?.task_state?.has_plan) { this.removePlan() }
      // If there is a current job displayed on the map, and the new status does not have a job, remove job from map.
      if (map.currentJob?.jobId && !attributes.jobId) {
        map.currentJob.hide()
      }
      if (map.gmap && !map.gmap.center) { map.center() }
    } else if (content.type == 'job') {
      console.log("received JOB. content=", content)
      this.stripeAngleOverlay?.setMap(null)
      this.planPolyline?.setMap(null)
      // Updates the current job if any changes are made
      if (map.gmap && (content.jobId == map.currentJob?.jobId || content.isCurrentJob)) {
        map.updateCurrentJob(content)
        $(window).trigger('new-job', map)
      }
      // Create or update `PreviousJob` object
      if (typeof(previousJobs) != 'undefined') {
        previousJobs.addOrUpdateJob(content)
      }
    } else if (content.type == 'plan') {
      console.log("received PLAN. content=", content)
      // Set plan object with plan path and stripe angle
      if (content.stripe_angle_rad != null && content.path?.length) {
        this.plan = { path: content.path, stripeAngle: content.stripe_angle_rad * (180 / Math.PI) }
        if (map.gmap) { $(window).trigger('show-stripe-overlay') }
        this.toggleStripePlan()
      } else if (this.planPolyline) {
        this.removePlan()
      }
    } else if (content.isRecordingProgress) {
      map.status.update(content.mowerLatLng)
      map.status.updateInfo(content.headingDegFromNorth)
      this.mappingMenu.renderRecordingProgress(content)
    } else {
      console.info("received unknown content. type=", content.type)
    }
    this.showDebugInfo();
  }

  showOffline() {
    this.$offlineNotice.show()
    this.$gpsNotice.hide()
    // Google doesn't like display: none.
    // See https://developers.google.com/maps/documentation/javascript/reference/map?hl=en#Map.fitBounds
    this.$map.addClass('vis-hidden')
    $("#map-buttons").hide()
    $("#location-notice").hide()
    $("#previous-job-menu").hide()
    $("#collapsed-button").hide()
    $("#mow-cancel-buttons").children().addClass('d-none')
    if (this.updateReady) { $("#update-ready").hide() }
    // If the job edit form is showing, hide it and remove the job_edit segment of the path.
    if (this.isJobEditFormOpen()) {
      $("#job-edit-form-companion").hide()
      window.history.replaceState({}, document.title, `/companion/${location.pathname.split('/')[2]}`)
    }
    $(window).trigger("show-offline")
  }

  hideOffline() {
    this.$offlineNotice.hide()
    $(window).trigger("hide-offline")
  }

  showGps() {
    this.hideOffline()
    this.$gpsNotice.show()
    this.$map.addClass('vis-hidden')
  }

  showMap() {
    this.hideOffline()
    $("#map-buttons").show()
    $("#previous-job-menu").show()
    $("#collapsed-button").show()
    this.$gpsNotice.hide()
    this.$map.removeClass("vis-hidden")
    if (this.updateReady && $('.page[data-name="Map"]').hasClass("active")) {
      $("#update-ready").show()
    }
    // If mower loses service temporarily and edit button is pressed while map is still displayed, this prevents
    // display issues when the mower comes back online
    if (previousJobs.jobToEdit && !location.href.includes('job_edit') && previousJobs.jobToEdit != map.currentJob) {
      previousJobs.hideJobToEdit()
    }
    $(window).trigger("show-map")
  }

  isShowingOfflineNotice() {
    return this.$offlineNotice.css("display") != 'none'
  }

  isShowingMap() {
    return this.$map.css("display") != 'none'
  }

  shouldHideNotices() {
    return $('.page.active[data-hide-notices="true"]').length > 0
  }

  isNoticeShowing() {
    return $(".top_notice.show").length > 0
  }

  /*
   * Sets the map to appropiate display based on whether there is a
   * a status location w/GPS data.
   */
  toggleMapDisplay(statusLatLng) {
    console.debug("Toggling map display for statusLatLng=",statusLatLng)
    if (!statusLatLng) {
      this.showOffline()
    } else if (!statusLatLng.lat) {
      this.showGps()
    } else {
      this.showMap()
    }
  }

  setVersionUpdateDescription() {
    if (!$('#version-current').length) {
      if (this.updateReady) {
        $('#version-update-description').html('Mower will install update at next startup')
      } else if (this.updateReady === false) {
        $('#version-update-description').html('Mower is currently downloading software update')
      }
    }
  }

  // If the mower status contains mowed areas coordinates, render as a MowingProgress polygon on the map
  showMowingProgress() {
    if (this.mowingProgress) {
      this.mowingProgress.hide()
      this.mowingProgress = null
    }
    if (this.mowingProgressFromStatus) {
      this.mowingProgress = new MowingProgress(this.mowingProgressFromStatus)
    }
  }

  // Render the plan path on the map
  renderPlan() {
    this.planPolyline?.setMap(null)
    this.planPolyline = new google.maps.Polyline({
      path: this.plan.path,
      geodesic: true,
      strokeColor: "#000000",
      strokeOpacity: 1.0,
      strokeWeight: 1,
      clickable: false
    })
    this.planPolyline.setMap(map.gmap)
  }

  // Remove plan overlay, show stripe overlay, and toggle selected icon
  showStripeAngleOverlay() {
    this.planPolyline?.setMap(null)
    this.stripeAngleOverlay?.setMap(map.gmap)
  }

  // Show plan overlay, remove stripe overlay, and toggle selected icon
  showPlan() {
    this.stripeAngleOverlay?.setMap(null)
    this.renderPlan()
  }

  // Removes plan and stripe overlays when an empty plan is posted
  removePlan() {
    this.planPolyline?.setMap(null)
    this.planPolyline = null
    this.plan = null
    if (map.gmap) {
      this.stripeAngleOverlay?.setMap(null)
      this.stripeAngleOverlay = null
    }
  }

  // Switch between showing stripe angle overlay and plan, if available, depending on map zoom level
  toggleStripePlan() {
    if (map.gmap && !this.isJobEditFormOpen()) {
      if (this.plan && map.gmap.getZoom() > 17) {
        this.showPlan()
      } else if (this.stripeAngleOverlay) {
        this.showStripeAngleOverlay()
      }
    }
  }

  isJobEditFormOpen() {
    return $("#job-edit-form-companion").is(':visible') && !!$("#job-edit-form-companion").length
  }
}
