import consumer from "./consumer"

/*
 * Handles communication with the companion ui.
 * The subscription is only created if on the companion ui view by checking that the
 * head tag has a non-null `data-mower-id` attribute.
 */
 $(document).on('turbolinks:load', function() {
   let mowerId = $('head').data('mower-id')
   if (typeof(mowerId) == "undefined") { return }
   createSubscription(mowerId)
 });

function createSubscription(mowerId) {
  consumer.subscriptions.create({channel: "AlertsChannel", mower_id: mowerId}, {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log("connected to alerts!")
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      // console.log("Received:")
      // console.log(data.content)
      $(window).trigger("new-content",data.content)
    }
  });
}
