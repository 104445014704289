const STROKE_COLOR = {
  'record_and_repeat': '#01377d',
  'keep_out_zone_mapping': '#ad0000',
  'perimeter_mapping': '#b3ff00'
}

// Logic for rendering a recording progress polyline for keep-out zones, record and repeat jobs, and job perimeters on
// the companion map
export default class RecordingProgress {
  constructor(progress) {
    this.path = progress.points
    // `keep_out_zone`, `record_and_repeat`, or `perimeter`
    this.type = progress.type
    // The google.maps.Polyline representing recording progress
    this.polyline = this.toPolyline()
    this.render()
  }

  // Returns a google.maps.Polyline for this path
  toPolyline() {
    return new google.maps.Polyline({
      path: this.path,
      strokeColor: STROKE_COLOR[this.type],
      strokeOpacity: 1.0,
      strokeWeight: 3,
      zIndex: 100
    })
  }

  // Renders the polyline on the google map
  render() {
    this.polyline.setMap(map.gmap)
  }

  // Updates the polyline with new points
  update(points) {
    this.path = points
    this.polyline.setPath(this.path)
  }

  // Remove progress from map when completed or canceled
  hide() {
    this.polyline.setMap(null)
  }

  // The HTML element for the corresponding recording progress menu
  menuElement() {
    return $(`#${this.type.replaceAll('_', '-')}-progress`)
  }

  isMenuVisible() {
    return this.menuElement().is(":visible")
  }
}
