// Module for defining gtag function, and pushing page views to Google analytics
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('set', { 'custom_map': { 'dimension1': 'event_type' } });

let measurementId = process.env.MEASUREMENT_ID

document.addEventListener("turbolinks:load", function(event) {
  if(typeof(gtag) == 'function'){
    gtag('config', measurementId, {
      page_location: event.data.url,
      page_path: event.srcElement.location.pathname,
      page_title: event.srcElement.title
    });
  }
})

export default gtag
