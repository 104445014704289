/*
 * Logic for rendering data on a Google Map on the system_statuses/show view.
 *
 */

/*
 * SVGs below are for representing different alert sources.
 */

// https://icons.getbootstrap.com/icons/camera-video-off-fill/
const svgObstacleMarker = {
  path: "M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l6.69 9.365zm-10.114-9A2.001 2.001 0 0 0 0 5v6a2 2 0 0 0 2 2h5.728L.847 3.366zm9.746 11.925-10-14 .814-.58 10 14-.814.58z",
  fillColor: "yellow",
  fillOpacity: 1.0,
  strokeWeight: 0.5,
  rotation: 0,
  scale: 1.5,
};

// https://icons.getbootstrap.com/icons/geo-fill/
const svgGpsMarker = {
  path: "M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z",
  fillColor: "yellow",
  fillOpacity: 1.0,
  strokeWeight: 1,
  rotation: 0,
  scale: 1.5,
};

// https://icons.getbootstrap.com/icons/exclamation-triangle-fill/
const svgAlertMarker = {
  path: "M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z",
  fillColor: "orange",
  fillOpacity: 1.0,
  strokeWeight: 1,
  rotation: 0,
  scale: 1.5,
};

// Event Monitor Icons

// Default event icon
const svgEvent = {
  path: "M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001",
  fillColor: "blue",
  fillOpacity: 1.0,
  strokeWeight: 1,
  rotation: 0,
  scale: 1,
}

// Admin web app event (companion app)
const svgEventAdminwebapp = {
  path: "M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z",
  fillColor: "blue",
  fillOpacity: 1.0,
  strokeWeight: 1,
  rotation: 0,
  scale: 1.3,
}

// Operator Control Panel (on the mower) event
const svgEventOCP = {
  path: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6z",
  fillColor: "blue",
  fillOpacity: 1.0,
  strokeWeight: 1,
  rotation: 0,
  scale: 1.3,
}

// Map event
const svgEventMap = {
  path: "M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001",
  fillColor: "blue",
  fillOpacity: 1.0,
  strokeWeight: 1,
  rotation: 0,
  scale: 1.3,
}

// Mow event
const svgEventMow = {
  path: "m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z",
  fillColor: "blue",
  fillOpacity: 1.0,
  strokeWeight: 1,
  rotation: 0,
  scale: 1.3,
}

export default class {
 /*
  * Params:
  * gmap - google.maps.Map object
  * coords - An array of lat/lng objects across all CSV rows w/a rostime. Should include all rows with a
  *          rostime, including those w/invalid GPS coords.
  * autoCoords - An array of lat/lng objects where the mower was under autonomous control. Should only
  *               include valid GPS coords.
  * teleopCoords - An array of lat/lng objects where the mower was under teleop control. Should only
  *               include valid GPS coords.
  * operatorCoords - Same as `auto_coords` but for when the mower is under operator control.
  * alerts - An array of `MowerAlert` objects that correspond to individual
  *                alerts generated during the run.
  * planPaths - A 2D array of mower plan path lat/lng coords. Each higher-level element represents a plan.
  * previousMaps - An array of objects containg the paths of previous mower maps.
  * rosBagSets - An array of ros bag set objects to render (contains gps coord of marker position and the estimated path over the duration of the ros bag set).
  * mowerIconPath - Path to the icon that represents the mower.
  * rosBagIconPath - A URL to an image to use for ros bag set marker icons.
  * eventTeleopIconPath - A URL to the image to display teleop events.
  */
  constructor(gmap, coords, autoCoords, teleopCoords, operatorCoords, alerts,
              planPaths, previousMaps, rosBagSets, events, mowerIconPath,
              rosBagIconPath, eventTeleopIconPath) {
    this.gmap = gmap
    this.coords = coords
    this.planPaths = []
    this.autoCoords = []
    this.teleopCoords = []
    this.operatorCoords = []
    this.alerts = []
    this.previousMaps = []
    this.rosBagSets = []
    this.events = []
    this.rosBagIconPath = rosBagIconPath
    this.eventTeleopIconPath = eventTeleopIconPath
    var _this = this;
    // stores references to google objects added to the map
    this.mapLayers = {
      planPolylines: [],
      autoPolylines: [],
      operatorPolylines: [],
      teleopPolylines: [],
      alertMarkers: [],
      previousMapPolygons: [],
      previousMapMarkers: [],
      rosBagSetMarkers: [],
      eventMarkers: []
    }

    // first item is added to the bottom
    this.renderPreviousMaps(previousMaps)
    this.renderPlans(planPaths)
    this.renderAutoPath(autoCoords)
    this.renderTeleopPath(teleopCoords)
    this.renderOperatorPath(operatorCoords)
    this.renderAlertMarkers(alerts)
    this.renderRosBagMarkers(rosBagSets)
    this.renderRosBagPaths(rosBagSets)
    this.renderEvents(events)

    // Revealed when clicking on an alert marker.
    this.infowindow = new google.maps.InfoWindow();

    // Shows the position when hovering over the velocity timeline chart.
    this.currentPositionMarker = new google.maps.Marker({
      map: _this.gmap,
      // Anchor attribute moves the anchor point to the center of the icon using coords relative to the icon size.
      icon: {url: mowerIconPath, anchor: new google.maps.Point(16, 17)},
    })

    if (this.queryParamMapBounds()) {
      this.gmap.fitBounds(this.queryParamMapBounds(), 0)
    } else {
      this.centerMap()
    }

    // Listens for the `point-hover` event fired from highcharts
    $(window).on("point-hover", function(event, index) {
      _this.updateCurrentPositionFromIndex(index)
    })
    // Listen for the map to become idle have changes.
    this.gmap.addListener("idle", () => {
      _this.updateMapQueryParam()
    });

    // map-display-toggles
    $("#map-display-toggles input[type='checkbox']").on('change', (e) => {
      $(window).trigger(e.target.id, e.target.checked)
    })
    this.addDisplayToggleListeners()
    // initial map layer display - must go after addDisplayToggleListeners()
    $("#map-display-toggles input[type='checkbox']").each((i, item) => {
      console.debug('initial', item.id, item.checked)
      // Previous maps are unchecked by default.
      if (item.id == 'display-previous-maps') {
        $(window).trigger(item.id, item.checked = false)
      } else {
        $(window).trigger(item.id, item.checked)
      }
    });
  }

  addDisplayToggleListeners() {
    var _this = this
    // autonomous mowing
    $(window).on("display-auto-mowing", (e, state) => {
      if (state) {
        _this.mapLayers.autoPolylines.forEach(item => {
          item.setMap(_this.gmap)
        });
      } else {
        _this.mapLayers.autoPolylines.forEach(item => {
          item.setMap(null)
        });
      }
    })
    // teleop mowing
    $(window).on("display-teleop-mowing", (e, state) => {
      if (state) {
        _this.mapLayers.teleopPolylines.forEach(item => {
          item.setMap(_this.gmap)
        });
      } else {
        _this.mapLayers.teleopPolylines.forEach(item => {
          item.setMap(null)
        });
      }
    })
    // operator mowing
    $(window).on("display-operator-mowing", (e, state) => {
      if (state) {
        _this.mapLayers.operatorPolylines.forEach(item => {
          item.setMap(_this.gmap)
        });
      } else {
        _this.mapLayers.operatorPolylines.forEach(item => {
          item.setMap(null)
        });
      }
    })
    // alerts
    $(window).on("display-alerts", (e, state) => {
      if (state) {
        _this.mapLayers.alertMarkers.forEach((item, i) => {
          item.setMap(_this.gmap)
        });
      } else {
        _this.mapLayers.alertMarkers.forEach((item, i) => {
          item.setMap(null)
        });
      }
    })
    // plans
    $(window).on("display-plans", (e, state) => {
      if (state) {
        _this.mapLayers.planPolylines.forEach((item, i) => {
          item.setMap(_this.gmap)
        });
      } else {
        _this.mapLayers.planPolylines.forEach((item, i) => {
          item.setMap(null)
        });
      }
    })
    // previous maps
    $(window).on("display-previous-maps", (e, state) => {
      var items = _this.mapLayers.previousMapPolygons.concat(_this.mapLayers.previousMapMarkers)
      if (state) {
        items.forEach((item, i) => {
          item.setMap(_this.gmap)
        });
      } else {
        items.forEach((item, i) => {
          item.setMap(null)
        });
      }
    })
    // events
    $(window).on("display-events", (e, state) => {
      if (state) {
        _this.mapLayers.eventMarkers.forEach((item, i) => {
          item.setMap(_this.gmap)
        });
      } else {
        _this.mapLayers.eventMarkers.forEach((item, i) => {
          item.setMap(null)
        });
      }
    })
  }

  /*
   * Updates the browser location query params to include the map bounds.
   */
  updateMapQueryParam() {
    var bounds = this.gmap.getBounds().toJSON()
    window.history.replaceState({}, '', `${location.pathname}?${$.param(bounds)}`);
  }

  queryParamMapBounds() {
    var bounds = null
    if (this.hasBoundsQueryParams()) {
      const params = new URLSearchParams(location.search);
      bounds = {
        north: parseFloat(params.get("north")),
        east: parseFloat(params.get("east")),
        south: parseFloat(params.get("south")),
        west: parseFloat(params.get("west")),
      }
      // TODO - DRY, iterate
      if (isNaN(bounds.north) || isNaN(bounds.east) || isNaN(bounds.south) || isNaN(bounds.west)) { bounds = null }
    }
    return bounds
  }

  hasBoundsQueryParams() {
    var res = false
    const params = new URLSearchParams(location.search);
    if (params.get("south")) { res = true}
    return res
  }

  updateCurrentPositionFromIndex(index) {
    var coord = this.coords[index]
    this.currentPositionMarker.setPosition(coord)
  }

  centerMap() {
    let params = new URLSearchParams(location.search);
    // Basic - not calculating a centoid. Just grabs first autoCoord or operator coords
    // since they should have a valid GPS.
    this.gmap.setCenter(this.autoCoords[0]?.[0] || this.operatorCoords[0]?.[0])
  }

  renderPreviousMaps(maps) {
    var _this = this
    maps.forEach((m, i) => {
      var mm = new Job(m, _this.gmap)

      mm.polygon = mm.toPolygon()
      mm.polygon.set("map_id",m.id)
      _this.mapLayers.previousMapPolygons.push(mm.polygon)

      mm.show(_this.gmap)

      var marker = new google.maps.Marker({
        position: mm.centroid(),
        map_id: m.id,
        map: _this.gmap,
        label: `${m.time_string}`
      });
      _this.mapLayers.previousMapMarkers.push(marker)
    })
  }

  /*
   * Renders the mower `planPaths` onto the map.
   */
  renderPlans(planPaths) {
    var _this = this;
    this.planPaths = planPaths
    planPaths.forEach((coords, i) => {
      var path = new google.maps.Polyline({
        path: coords,
        geodesic: true,
        strokeColor: "black",
        strokeOpacity: 1.0,
        strokeWeight: 1,
      });
      path.setMap(_this.gmap)
      _this.mapLayers.planPolylines.push(path)
    });
  }

  /*
   * Renders the autonomous mowing path.
   */
  renderAutoPath(coords) {
    this.autoCoords = coords
    coords.forEach(coord => {
      const mowerPathAuto = new google.maps.Polyline({
        path: coord,
        geodesic: true,
        strokeColor: "darkgreen",
        strokeOpacity: 1.0,
        strokeWeight: 3,
      });
      mowerPathAuto.setMap(this.gmap);
      this.mapLayers.autoPolylines.push(mowerPathAuto)
    })
  }

  /*
   * Renders the teleop mowing path.
   */
  renderTeleopPath(coords) {
    this.teleopCords = coords
    coords.forEach(coord => {
      const mowerPathTeleop = new google.maps.Polyline({
        path: coord,
        geodesic: true,
        strokeColor: "yellow",
        strokeOpacity: 1.0,
        strokeWeight: 3,
      });
      mowerPathTeleop.setMap(this.gmap);
      this.mapLayers.teleopPolylines.push(mowerPathTeleop)
    })
  }

  /*
   * Renders the operator path.
   */
   renderOperatorPath(coords) {
     this.operatorCoords = coords
     coords.forEach(coord => {
       const mowerPathOp = new google.maps.Polyline({
         path: coord,
         geodesic: true,
         strokeColor: "red",
         strokeOpacity: 1.0,
         strokeWeight: 2,
       });
       mowerPathOp.setMap(this.gmap);
       this.mapLayers.operatorPolylines.push(mowerPathOp)
     })
   }

  /*
   * Renders alerts as markers on the map.
   * Adds an event listener when clicking on a marker to open an
   * info box with additional details on the alert.
   */
   renderAlertMarkers(alerts) {
     var _this = this
     this.alerts = alerts
     alerts.forEach((alert, i) => {
       var marker = new google.maps.Marker({
         position: alert.gps,
         map: _this.gmap,
         icon: _this.iconForAlert(alert),
        // If there are too many alerts, gmap will optimize using canvas which can affect scaling of the icons.
         optimized: false
        });
       marker.addListener("click", () => {
         var content = JSON.stringify(alert,null,'<br/>')
         _this.infowindow.setContent(alert.content)
         _this.infowindow.open({
           anchor: marker,
           map: _this.gmap,
           shouldFocus: false,
         });
       });
       _this.mapLayers.alertMarkers.push(marker)
     });
   }

  /*
   * Renders a marker for each of the ros bag sets in the array of `rosBagSets`.
   */
  renderRosBagMarkers(rosBagSets) {
    var _this = this
    this.rosBagSets = rosBagSets
    rosBagSets.forEach((rosBagSet, i) => {
      var marker = new google.maps.Marker({
        position: rosBagSet.gps,
        map: _this.gmap,
        // Anchor attribute moves the anchor point to the center of the icon using coords relative to the icon size.
        icon: {url: _this.rosBagIconPath, anchor: new google.maps.Point(12, 12)},
        optimized: false
      });
      marker.addListener("click", () => {
        _this.infowindow.setContent(rosBagSet.content)
        _this.infowindow.open({
          anchor: marker,
          map: _this.gmap,
          shouldFocus: false,
        });
      });
      _this.mapLayers.rosBagSetMarkers.push(marker)
    });
  }

  /*
   * Render a marker for each event from the event monitor log.
   */
  renderEvents(events) {
    var _this = this
    this.events = events
    events.forEach((e, i) => {
      var marker = new google.maps.Marker({
        position: e.gps,
        map: _this.gmap,
        icon: _this.iconForEvent(e),
        // If there are too many events, gmap will optimize using canvas which can affect scaling of the icons.
        optimized: false
      });
      marker.addListener("click", () => {
        _this.infowindow.setContent(e.content)
        _this.infowindow.open({
          anchor: marker,
          map: _this.gmap,
          shouldFocus: false,
        });
      });
      _this.mapLayers.eventMarkers.push(marker)
    });
  }

  /*
   * Renders a polyline for the coords associated with each ros bag set.
   */
  renderRosBagPaths(rosBagSets) {
    rosBagSets.forEach((rosBagSet, i) => {
      const rosBagSetPath = new google.maps.Polyline({
        path: rosBagSet.coords,
        geodesic: true,
        strokeColor: "#835AEC",
        strokeOpacity: 0,
        // dashed line
        icons: [
          {
            icon: {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              scale: 3,
            },
            offset: "0",
            repeat: "20px",
          },
        ],
      });
      rosBagSetPath.setMap(this.gmap);
    });
  }

  /*
   * Returns an icon to to represent the alert based on the source.
   */
  iconForAlert(alert) {
    var icon = svgAlertMarker
    if (alert.source.includes("monitor_obstacles")) {
      icon = svgObstacleMarker
    } else if (alert.source.includes("gps")) {
      icon = svgGpsMarker
    }
    // Moves the anchor point to the center of the icon using coords relative to the icon size.
    icon.anchor = new google.maps.Point(8, 9)
    return icon
  }

  /*
   * Returns an icon to to represent the event based on the sender.
   */
  iconForEvent(event) {
    var icon = svgEvent
    if (event.message.toLowerCase().includes("map")) {
      icon = svgEventMap
      // Moves the anchor point to the center of the icon using coords relative to the icon size.
      icon.anchor = new google.maps.Point(8, 9)
    } else if (event.message.toLowerCase().includes("mow!")) {
      icon = svgEventMow
      icon.anchor = new google.maps.Point(8, 9)
    } else if (event.sender.includes("adminwebapp.py")) {
      icon = svgEventAdminwebapp
      icon.anchor = new google.maps.Point(8, 9)
    } else if (event.sender.includes("teleop_joy")) {
      icon = {url: this.eventTeleopIconPath, scaledSize: new google.maps.Size(25, 25), anchor: new google.maps.Point(12.5, 12.5)}
    } else if (event.sender == "/") {
      icon = svgEventOCP
      icon.anchor = new google.maps.Point(8, 9)
    }
    return icon
  }
}
