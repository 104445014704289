// Get distance in miles between two lat/longs
// https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates

export default class {
  constructor(lat1, lng1, lat2, lng2) {
    this.distanceInMiles = this.getDistanceInMiles(lat1, lng1, lat2, lng2)
  }

  getDistanceInMiles(lat1, lng1, lat2, lng2) {
    var R = 3958.8; // Radius of the earth in miles
    var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
    var dLon = this.deg2rad(lng2-lng1);
    var a =
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c; // Distance in miles
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI/180)
  }
}
