import Job from "custom/companion/job"
import JobMarker from "custom/companion/job_marker"

// Inherits from Job. Additional logic for displaying previous jobs.
export default class PreviousJob extends Job {
  constructor(job, label) {
    super(job)
    this.job = job
    this.jobId = job.jobId
    this.favorite = job.favorite
    this.polygon = null
    this.jobMarker = null
    this.label = label

    $(window).on("map-rendered", (event) => {
      this.jobMarker = new JobMarker(this)
    })
  }

  hide() {
    super.hide()
    this.hideMarker()
  }

  show() {
    super.show()
    this.jobMarker?.marker?.setMap(map.gmap)
  }

  hideMarker() {
    this.jobMarker.marker.setMap(null)
  }

  // Creates polygon and sets visibility based on recent or favorite.
  toPolygon() {
    return new google.maps.Polygon({
      paths: this.paths,
      strokeColor: 'rgb(2, 123, 255)',
      strokeWeight: 2,
      strokeOpacity: 0.7,
      fillOpacity: 0.7,
      fillColor: '#4a4a4aff',
      zIndex: 0
    })
  }

  updateName(name) {
    this.label = name
    this.jobMarker.updateLabel(name)
  }

  // Create polygon and job marker for a newly posted job. This is typically done on page load from event listeners.
  renderNew() {
    this.polygon = this.toPolygon()
    this.jobMarker = new JobMarker(this)
  }

  // Sets polygon gray with blue outline for unselected jobs.
  setGray() {
    this.polygon.setOptions({
      strokeColor: 'rgb(2, 123, 255)',
      strokeWeight: 2,
      strokeOpacity: 0.7,
      fillOpacity: 0.7,
      fillColor: '#4a4a4aff',
      zIndex: 0
    })
  }

  // Sets polygon clear for current job, which is already displayed with the Job class.
  setClear() {
    this.polygon.setOptions({
      strokeOpacity: 0,
      fillOpacity: 0
    })
  }

  className() {
    return 'PreviousJob'
  }
}
