// import "custom/companion/stripe_angle_overlay"

// Logic for rendering all jobs on a map.
export default class {
  constructor(gmap, jobs) {
    this.gmap = gmap
    this.job = null
    this.infowindow = null

    var _this = this;

    this.mapLayers = {
      jobPolygons: [],
      jobMarkers: []
    }

    this.renderJobs(jobs)

    // if (this.queryParamMapBounds()) {
    //   this.gmap.fitBounds(this.queryParamMapBounds(), 0)
    // } else {
      this.centerMap()
    // }

    // // Listen for the map to become idle have changes.
    // this.gmap.addListener("idle", () => {
    //   _this.updateMapQueryParam()
    // })

    google.maps.event.addListener(this.gmap, "click", () => {
      if (_this.infoWindow) {
        _this.closeInfoWindow()
      }
    })
  }

  // // Updates the browser location query params to include the map bounds.
  // updateMapQueryParam() {
  //   var bounds = this.gmap.getBounds().toJSON()
  //   window.history.replaceState({}, '', `${location.pathname}?${$.param(bounds)}`);
  // }

  // queryParamMapBounds() {
    // var bounds = null
    // if (this.hasBoundsQueryParams()) {
    //   const params = new URLSearchParams(location.search);
    //   bounds = {
    //     north: parseFloat(params.get("north")),
    //     east: parseFloat(params.get("east")),
    //     south: parseFloat(params.get("south")),
    //     west: parseFloat(params.get("west")),
    //   }
    //   // TODO - DRY, iterate
    //   if (isNaN(bounds.north) || isNaN(bounds.east) || isNaN(bounds.south) || isNaN(bounds.west)) { bounds = null }
    // }
    // return bounds
  // }

  hasBoundsQueryParams() {
    var res = false
    const params = new URLSearchParams(location.search);
    if (params.get("south")) { res = true}
    return res
  }

  centerMap() {
    // let params = new URLSearchParams(location.search);
    let bounds = new google.maps.LatLngBounds()
    this.mapLayers.jobPolygons.forEach((job) => {
      bounds.union(job.polygon.getBounds())
    })
    this.gmap.fitBounds(bounds)

    // this.gmap.setCenter([0,0])
  }

  renderJobs(jobs) {
    var _this = this
    jobs.forEach((jobData) => {
      var job = new Job(jobData, _this.gmap)

      job.addPaths()
      if (!job.isRecordAndRepeat) { job.keepOutZonePolygons = job.renderKeepOutZonePolygons() }
      job.polygon.set("map_id", jobData.id)
      _this.mapLayers.jobPolygons.push(job)

      job.show()

      var pinColor = "#017BFF";
      var pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
      var labelOriginFilled = new google.maps.Point(12, 9);

      var markerImage = {
        path: pinSVGFilled,
        anchor: new google.maps.Point(12, 17),
        fillOpacity: 1,
        fillColor: pinColor,
        strokeWeight: 1,
        strokeColor: "#0066D5",
        scale: 1.8,
        labelOrigin: labelOriginFilled
      }

      var marker = new google.maps.Marker({
        position: job.centroid(),
        label: {
          text: jobData.name || new Date(jobData.created_at).toLocaleString("en-US", { month: 'numeric', day: 'numeric', year: '2-digit', hour: 'numeric', minute: 'numeric' }).replace(',', ''),
          color: "white",
          fontSize: "12px",
          title: `marker-${jobData.jobId}`,
        },
        polygon: job.polygon,
        job_id: jobData.id,
        icon: markerImage,
      });
      _this.mapLayers.jobMarkers.push(marker)

      var items = _this.mapLayers.jobMarkers
        items.forEach((item, i) => {
          item.setMap(_this.gmap)
        })

      var items = _this.mapLayers.jobPolygons
        items.forEach((item, i) => {
          item.show(_this.gmap)
        })

      // When marker is clicked, open info window, close previous info window if open, and center on job.
      marker.addListener("click", () => {
        this.closeInfoWindow()
        this.openInfoWindow(marker, job)
      })

      google.maps.event.addListener(job.polygon, "click", () => {
        // this.clickPolygon()
        _this.closeInfoWindow()
        this.openInfoWindow(marker, job)
        // $(`#marker-${this.job.jobId}`).addClass("active")
      })
    })
  }

  openInfoWindow(marker, job) {
    this.infoWindow = new google.maps.InfoWindow({
      content: this.infoWindowContent(marker, job),
      marker: marker,
    })
    this.infoWindow.open({
      anchor: marker,
      map: this.gmap,
    })
    marker.polygon.setOptions({
      strokeColor: '#40ff00',
      strokeWeight: 4,
      strokeOpacity: 0.9,
      fillColor: '#026101',
      zIndex: 100
    })

    this.gmap.fitBounds(job.polygon.getBounds())

    this.previousMarker = marker
    this.job = job

    google.maps.event.addListener(this.infoWindow, 'closeclick', () => {
      this.closeInfoWindow()
    })

    // $(`#${this.activeTab()}-button-${job.jobId}`).addClass('active')
    // google.maps.event.addListener(this.infoWindow, 'closeclick', () => {
    //   this.clearInfoWindow()
    // })
    $.ajax({
      type: 'GET',
      url: `/jobs/${job.jobId}`,
      // data: { mower_id: location.pathname.split('/')[2] },
      success: (jobInfo) => {

      },

      error: (e) => {
        console.debug(`${e.responseText} - ${e.status}: ${e.statusText}`)
      }
    })
    // $(window).trigger('jobs-map-rendered', job, this.gmap)
  }

  closeInfoWindow() {
    $('#job-form').html('')
    $('#job-payload').html('')
    this.infoWindow?.close()
    this.job?.setGreen()
  }

  infoWindowContent(jobMarker, job) {
    return `Job ID: ${job.jobId}`
    // let repeats = job.repeats ? '<i class="bi bi-arrow-repeat" style="font-size:14px"></i>' : ''
    // return `<div class="d-flex flex-column">
    //           <div class="d-flex flex-row" style="height:40px">
    //             <div>
    //               <button class="btn p-0">
    //                 <i id="info-window-star" class="bi bi-star-fill text-success position-relative ml-1" onclick='$(window).trigger("favorite-star", "${job.jobId}")' style="font-size:40px;top:-10px"></i>
    //               </button>
    //             </div>
    //           </div>
    //         </div>
    //         <div>
    //           <div class="d-flex flex-row justify-content-between">
    //             <div>
    //               <a data-confirm="Are you sure you want to delete this job?" class="btn p-0" data-method="delete" href="/jobs/${job.recordId}?mower_id=${location.pathname.split('/')[2]}">
    //                 <i class="bi bi-trash" style="font-size:34px;color:#dc3545"></i>
    //               </a>
    //             </div>
    //             <div>
    //               <form style="display:inline" action="/companion/${location.pathname.split('/')[2]}/job_edit" accept-charset="UTF-8" method="get">
    //                 <input type="hidden" id="job_id" name="job_id" value="${job.jobId}">
    //                 <button class="btn p-0" type="submit">
    //                   <i id="job-edit-button" class="bi bi-pencil-square" style="font-size:34px;color:#666"></i>
    //                 </button>
    //               </form>
    //             </div>
    //             <div class='duplicate-button' style='width:34px;'>
    //               <a data-confirm="Are you sure you want to duplicate this job?" class="btn p-0 pt-2" data-method="post" href="/jobs/${job.recordId}/duplicate?mower_id=${location.pathname.split('/')[2]}">

    //               </a>
    //             </div>
    //           </div>
    //         </div>`
  }
}
