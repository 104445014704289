// CSS animiation classes applied to pages as they are turned into the active page.
const CSS_SLIDE_IN_LEFT = "slide_in_from_left"
const CSS_SLIDE_IN_RIGHT = "slide_in_from_right"

// CSS class applied to the previously active page.
const CSS_PREVIOUSLY_ACTIVE_PAGE = "previous_active"

/**
 * Logic for a mobile-ready slide navigation.
 * Example usage:
 * slideNav = new SlideNav(".menu_swipe", "#main")
 *
 * See the companion ui for a full working example and required HTML markup.
*/
export default class {
  constructor(content_el_id) {
    // listens for swipe events in this container
    this.$content = $(content_el_id)
    // the bullets that represent pages in the slide nav
    this.$pages = $(".menu_swipe").find(".menu_item")
    // the containers that hold the content for each page
    this.$pageContainers = $(".page")

    var _this = this


    this.setPageContainerZIndexes()

    // Set the active page
    this.setActive();

    // Add page bullet event handler
    this.$pages.on("click", function(e) {
      var $page = $(e.target).closest(".menu_item")
      _this.visit($page.data("name"))
    })

    // Add history popstate listeners so fwd/back buttons work in browser
    window.addEventListener("popstate", function(popstateEvent) {
      console.log("popstateEvent=", popstateEvent)
      _this.setActive()
    })

    // Listen for custom triggered visit events and navigate to the page
    $(window).on("visit", function(event) {
      _this.visit(event.page)
    })
  }

  /*
   * Applies initial z-indexes to the page containers.
   * The first container gets the lowest index, then they increment by one.
   */
  setPageContainerZIndexes() {
    this.$pageContainers.each(function(i, ele) {
      $(ele).css("z-index",i)
    })
  }

  /*
   * Makes the page w/+name+ the new active page.
   * If +name+ is undefined, returns +false+ and performs no action.
   */
  visit(name) {
    if (typeof(name) == 'undefined') { return false }
    console.log("visiting name=",name)
    let [$page, $pageContainer] = this.getPagePair(name)
    window.history.pushState({}, '', $page.data("url"));
    this.setActive()
  }

  // Returns an Array w/$page and associated $pageContainer.
  getPagePair(name) {
    return [this.$page(name), this.$pageContainer(name)]
  }

  $page(name) {
    return this.$pages.filter(`*[data-name='${name}']`).eq(0)
  }

  $pageContainer(name) {
    return this.$pageContainers.filter(`*[data-name='${name}']`).eq(0)
  }

  // Sets the active page based on the browser URL and matching data-url in HTML.
  setActive() {
    var _this = this;
    this.$pages.each( function(i, page) {
      var $page = $(page)
      var pageUrl = new URL($page.data("url"))
      var path = window.location.pathname
      // job_edit path shows job edit form partial on top of map.
      if (path.includes('job_edit')) { path = path.substring(0, path.lastIndexOf('/')) }
      if (pageUrl.pathname == path) {
        $page.addClass("active")
        _this.setActivePageContainer($page)
      } else {
        $page.removeClass("active")
      }
    })
  }

  // Sets the active page container to match the +$page+.
  setActivePageContainer($page) {
    var $prevActivePageContainer = this.$updatePreviousActive()
    this.resetSlideAnimiation()
    var _this = this

    this.$pageContainers.each(function(i, pageContainer) {
      var $pageContainer = $(pageContainer)
      if ($pageContainer.data("name") == $page.data("name")) {
        let cssClass = _this.slideAnimationCssClass($prevActivePageContainer, $pageContainer)
        $pageContainer.addClass("active").addClass(cssClass)
        console.log($pageContainer.data("name"), cssClass)
      } else {
        $pageContainer.removeClass("active")
      }
    })
  }

  // Removes slide animation CSS classes from all pages.
  resetSlideAnimiation() {
    return this.$pageContainers.each(function(i, pageContainer) {
      $(pageContainer).removeClass(CSS_SLIDE_IN_LEFT).removeClass(CSS_SLIDE_IN_RIGHT)
    })
  }

  /*
   * Removes the CSS previously active class from all pages and applies it to the currently active page.
   */
  $updatePreviousActive() {
    this.resetPreviousActive();
    let $prevActivePageContainer = this.$activePageContainer()
    return $prevActivePageContainer.addClass(CSS_PREVIOUSLY_ACTIVE_PAGE)
  }

  /*
   * Removes the CSS class on the previously active page.
   * This CSS class is needed so that when sliding, the new page always slides over the previous page.
   */
  resetPreviousActive() {
    return this.$pageContainers.each(function(i, pageContainer) { $(pageContainer).removeClass(CSS_PREVIOUSLY_ACTIVE_PAGE)})
  }

  $active() {
    return this.$pages.filter(".active")
  }

  $activePageContainer() {
    return this.$pageContainers.filter(".active")
  }

  /*
   * Given the +$current+ page and the new +$new+ page, returns a CSS Class (String) to apply to the +$new+ page.
   * The slide direction animation is determined by the placement of the pages left-to-right.
   */
  slideAnimationCssClass($current,$new) {
    var animateClass = null;
    console.log("comparing", $current.data("name"), $new.data("name"))
    if (!$current.length || !$new.length) {
      animateClass = ""
    } else if (this.$pageContainers.index($new) > this.$pageContainers.index($current)) {
      animateClass = CSS_SLIDE_IN_RIGHT
    } else if (this.$pageContainers.index($new) < this.$pageContainers.index($current)) {
      animateClass = CSS_SLIDE_IN_LEFT
    }
    return animateClass
  }

  $next() {
    return this.$active().next()
  }

  next() {
    return this.$next().data("name")
  }

  $prev() {
    return this.$active().prev()
  }

  prev() {
    return this.$prev().data("name")
  }
}
