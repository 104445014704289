// Logic for rendering a mowing progress polygon on the map.
export default class MowingProgress {
  constructor(progress) {
    // An Array of objects containing lat/lng key-value pairs.
    this.paths = progress
    // The google.maps.Polygon representing mowing progress.
    this.polygon = this.toPolygon()
    this.render()
  }

  //  Returns a google.maps.Polygon for this mowing progress.
  toPolygon() {
    return new google.maps.Polygon({
      paths: this.paths,
      strokeColor: '#005d00',
      strokeOpacity: 0.9,
      strokeWeight: 2,
      fillColor: 'green',
      fillOpacity: 0.5,
      zIndex: 1000,
      clickable: false
    });
  }

  // Renders the polygon on the google map (google.maps.Map).
  render() {
    this.polygon.setMap(map.gmap)
  }

  // Remove mowing progress from map.
  hide() {
    this.polygon.setMap(null)
  }
}
