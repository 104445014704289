// This handles logic for creating google map markers, rendering markers on the map, adding marker buttons in the job
// reloading menu, sending request to rails controller to load a job, and handling the click events for the marker and polygon.
export default class {
  constructor(job) {
    this.marker = null
    // The PreviousJob object associated with this marker.
    this.job = job
    // The text to be displayed on the marker, either the job's name if given one, or the date created in the db.
    this.label = this.job.label
    this.starIcon = job.favorite ? "bi bi-star-fill" : "bi bi-star"

    let _this = this

    // When gmap polygon or button is clicked, open info window and center on job.
    $(window).on(`click-polygon-${this.job.jobId}`, () => {
        // If job is already selected, deselect it.
        if ($(`#marker-${_this.job.jobId}`).hasClass("active")) {
          previousJobs.closeInfoWindow()
          previousJobs.centerOnJobs()
        } else {
          _this.clickPolygon()
          $(`#marker-${_this.job.jobId}`).addClass("active")
        }
    })

    this.addMarker(job, previousJobs.gmap)
  }

  // Create google map marker and add it to the map, centered on the related job.
  // TODO: Refactor this function.
  addMarker(job) {
    var pinColor = "#017BFF";
    var pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
    var labelOriginFilled = new google.maps.Point(12, 9);

    var markerImage = {  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
      path: pinSVGFilled,
      anchor: new google.maps.Point(12, 17),
      fillOpacity: 1,
      fillColor: pinColor,
      strokeWeight: 1,
      strokeColor: "#0066D5",
      scale: 1.8,
      labelOrigin: labelOriginFilled
    };

    this.marker = new google.maps.Marker({
        position: job.centroid(),
        label: {
          text: this.label,
          color: "white",
          fontSize: "12px",
        },
        polygon: job.polygon,
        optimized: false,
        icon: markerImage,
        title: `marker-${job.jobId}`,
        visible: true,
        disableAutoPan: true
      });

    // When marker is clicked, open info window, close previous info window if open, and center on job.
    this.marker.addListener("click", () => {
      this.clickPolygon()
    })

    // When polygon is clicked, open info window, close previous info window if open, and center on job.
    google.maps.event.addListener(job.polygon, "click", () => {
      this.clickPolygon()
      $(`#marker-${this.job.jobId}`).addClass("active")
    })

    // When Load Job button is clicked, send request to rails controller to load the job.
    // If request to mower is successful, show success message, update current job, update polygon colors.
    // If request to mower is unsuccessful, show error message.
    // Any response from the mower is a success for this ajax call, even if the mower is not able to load the job.
    // The error callback here is for if the request to the rails controller fails.
    $(window).on(`load-map-${this.job.jobId}`, (event) => {
      if (!job.job.isEarthLlaCorrected) {
        alert("To enable reloading for this Job, please ensure your mower is up to date, and contact Greenzie to fix this Job")
      } else {
        $("#sending-command").addClass('show').html(`<div class="spinner-border mx-3" role="status" style="width:25px;height:25px"></div><div>Loading "${this.label}"...</div>`)
        event.stopImmediatePropagation()
        let _this = this

        $.ajax({
          type: 'POST',
          url: `/jobs/${job.jobId}/load`,
          dataType: "json",
          data: { mower_id: location.pathname.split('/')[2] },
          success: function(e) {
            console.debug(e.response)
            if (e.response.toLowerCase().includes('success')) {
              $("#sending-command").html(`<div>"${_this.label}" has been loaded</div>`)
              setTimeout(() => { $("#sending-command").removeClass('show') }, 5000)
              $(window).trigger("job-loaded")
              if ($('#previous-job-menu').hasClass('show')) {
                $("#recent-pill").hasClass("active") ? $("#recent-pill").trigger("click") : $("#favorite-pill").trigger("click")
              }
              previousJobs.closeInfoWindow()
              map.center()
            } else {
              $("#sending-command").removeClass('show')
              let message = e.response ? e.response : 'An error has occurred while loading the job, please try again or contact Greenzie Support.'
              $("#loading-error").html(message).addClass('show')
              setTimeout(() => { $("#loading-error").removeClass('show') }, 8000)
            }
          },
          error: function(e) {
            console.debug(`${e.responseText} - ${e.status}: ${e.statusText}`)
            $("#sending-command").removeClass('show')
            $("#loading-error").html('An error occurred while loading the job, please try again or contact Greenzie Support.').addClass('show')
            setTimeout(() => { $("#loading-error").removeClass('show') }, 8000)
          }
        })
      }
    })
  }

  // When gmap polygon or button is clicked, close existing info window, open new info window and center on job.
  clickPolygon() {
    previousJobs.closeInfoWindow()
    previousJobs.openInfoWindow(this)
    this.job.center()
  }

  // Add marker button to the recent or favorite jobs tab.
  addMarkerButton($container, type, isRecordAndRepeat) {
    if (type == 'favorite' && $("#no-favorite").length) {
      $("#favorite-jobs-carousel .carousel-inner").empty()
    }
    if (type == 'recent' && $("#no-recent").length) {
      $("#recent-jobs-carousel .carousel-inner").empty()
    }
    if (!$container?.length) {
      $(`#${type}-jobs-carousel .carousel-inner`).append(`<div class="carousel-item active"> <div id="${type}-buttons-0" class="d-flex align-content-stretch w-100"></div></div>`)
      $container = `#${type}-buttons-0`
    }
    let repeats = this.job.repeats ? ' <i class="bi bi-arrow-repeat" style="font-size:11px"></i>' : ''
    $($container).append(`<a class="btn btn-primary btn-lg border p-1 text-wrap align-middle" style="border-color:#0066D5!important;font-size:12px;height:50px;line-height:1!important;width:61px;text-overflow: ellipsis;overflow:hidden;white-space: nowrap;" id="${type}-button-${this.job.jobId}" onclick='$(window).trigger("click-polygon-${this.job.jobId}")'></i>${this.label}${repeats}</a>`)
  }

  // Update label text when job is renamed
  updateLabel(name) {
    let label = this.marker.getLabel()
    label.text = name
    this.marker.setLabel(label)
    $(`#recent-button-${this.job.jobId}`).html(name)
    $(`#favorite-button-${this.job.jobId}`).html(name)
  }
}
