// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// IMPORTANT: this is shared by both the platform side and the Companion app. As the companion app is
// typically used on a cell connection, limit the size of this and create a separate pack if using
// something heavy.

require("@rails/ujs").start()
require("turbolinks").start()

//require("@rails/activestorage").start()

require("channels")
require("bootstrap")
require("stupid-table-plugin")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

global.$ = $

import gtag from "custom/analytics"
global.gtag = gtag

import SlideNav from "custom/slide_nav"
global.SlideNav = SlideNav

import Companion from "custom/companion/companion"
global.Companion = Companion

import CompanionConnectionMonitor from "custom/companion/connection_monitor"
global.CompanionConnectionMonitor = CompanionConnectionMonitor

import CompanionRefresh from "custom/companion/refresh"
global.CompanionRefresh = CompanionRefresh

import CompanionJob from "custom/companion/job"
global.CompanionJob = CompanionJob

import CompanionGMap from "custom/companion/google_map"
global.CompanionGMap = CompanionGMap

import CompanionMappingGuide from "custom/companion/mapping_guide"
global.CompanionMappingGuide = CompanionMappingGuide

import TimeUtil from "custom/time_util"
const timeUtil = new TimeUtil()
global.timeUtil = timeUtil

import SystemStatusDisplay from "custom/system_status_display"
global.SystemStatusDisplay = SystemStatusDisplay

import JobsMap from "custom/jobs_map"
global.JobsMap = JobsMap

import Job from "custom/companion/job"
global.Job = Job

import sortTables from "custom/table_sort"

import Geolocation from "custom/companion/geolocation"
global.Geolocation = Geolocation

import DistanceUtil from "custom/distance_util"
global.DistanceUtil = DistanceUtil

import JobReloading from "custom/companion/job_reloading"
global.JobReloading = JobReloading

// Applies tooltips on page load
$(document).on('turbolinks:load', function() {
  console.debug("Applying tooltips")
  $('[data-toggle="tooltip"]').tooltip()

  // Sort tables using stupidsort if "table.sort" class exists
  sortTables()
})
