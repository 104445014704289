// Logic for rendering a a google maps polyline representing a Record and Repeat job's path
export default class RecordedPath {
  constructor(path) {
    // An array of objects containing lat/lng objects
    this.path = path
    // The google.maps.Polyline representing the path
    this.polyline = null
  }


  // Returns a polyline representing a record and repeat path
  toPolyline() {
    this.polyline = new google.maps.Polyline({
      path: this.path,
      strokeColor: "#01377d",
      strokeOpacity: 1.0,
      strokeWeight: 3,
      zIndex: 100,
      icons: []
    })
  }

  show(gmap, isCurrentJob) {
    isCurrentJob ? this.showIcons() : this.hideIcons()
    this.polyline.setMap(gmap)
  }

  hide() {
    this.polyline.setMap(null)
  }

  showIcons() {
    this.polyline.set('icons', map.currentJob.recordedPath.icons())
  }

  hideIcons() {
    this.polyline.set('icons', [])
  }

  // Icons to mark the start and end points for the path
  icons() {
    let circle = {
      path: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0",
      fillColor: '#01377d',
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 1.5,
      rotation: 0,
      anchor: new google.maps.Point(8, 8),
    }
    let triangle = {
      path: "M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445",
      strokeColor: '#ffffff',
      fillColor: '#ffffff',
      fillOpacity: 1,
      scale: 1.5,
      rotation: 0,
      anchor: new google.maps.Point(8, 8),
    }
    let flag = {
      path: "M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001",
      strokeColor: '#ffffff',
      fillColor: '#ffffff',
      fillOpacity: 1,
      scale: 0.6,
      rotation: 0,
      anchor: new google.maps.Point(8, 8),
    }
    return [
      {
        icon: circle,
        offset: "0%",
        fillOpacity: 1,
        strokeOpacity: 1.0,
        fixedRotation: true,
      },
      {
        icon: triangle,
        offset: "0%",
        fillOpacity: 1,
        strokeOpacity: 1.0,
        fixedRotation: true,
      },
      {
        icon: circle,
        offset: "100%",
        fillOpacity: 1,
        strokeOpacity: 1.0,
        fixedRotation: true,
      },
      {
        icon: flag,
        offset: "100%",
        fillOpacity: 1,
        strokeOpacity: 1.0,
        fixedRotation: true,
      }
    ]
  }
}
