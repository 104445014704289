/*
 * Handles refreshing the companion app data when the browser tab for the
 * app regains visibility.
 *
 * This is needed as the tab may not receive actioncable updates when not visible (esc. on mobile).
 *
 * Events:
 *
 * "new-content" - fn(object) - Triggered when a new status/alert/job/plan is received. See +to_companion in Ruby
 *                              objects for the format of each type.
 */

// If the window regains focus after this threshold data is refreshed.
// See refreshIfStale().
const STALE_THRESHOLD_IN_SECONDS = 10

export default class {
  constructor(mowerRefreshUrl, loadedAt, state) {
    // The element to display that contains the notice about the view refreshing w/latest data.
    this.$notice = $("#refresh_notice")
    // Base URL to GET w/the latest status and alert report. See mowerRefreshUrlWithParams() for the full URL.
    this.mowerRefreshUrl = mowerRefreshUrl;
    // Stores the last time a data refresh was triggered.
    this.lastRefreshAt = new Date()
    // Stores the time of the last alert report or status received as a Date.
    this.lastAlertUpdatedAt = new Date(loadedAt);
    // Stores the time of the last status received as a Date.
    this.lastStatusUpdatedAt = new Date(loadedAt);
    // Stores the time of the last job received as a Date.
    this.lastJobUpdatedAt = new Date(loadedAt);
    // Stores the time of the last plan received as a Date.
    this.lastPlanUpdatedAt = new Date(loadedAt);
    // Access overall state of the companion app
    this.state = state

    var _this = this

    // Trigger a refresh (if needed) when the window gains visibility.
    // jquery on() doesn't fire this event - using vanilla JS.
    // Robust solution is pretty complex: https://stereologics.wordpress.com/2015/04/02/about-page-visibility-api-hidden-visibilitychange-visibilitystate/
    document.addEventListener("visibilitychange", function() {
      if (!document.hidden) {
        _this.refreshIfStale()
      }
    }, false);
  }

  /*
   * Refreshes the view if a refresh hasn't been performed in a while.
   */
  refreshIfStale() {
    if (this.isStale()) {
      console.log(`View IS stale ${this.secondsSinceLastRefresh()}s > ${STALE_THRESHOLD_IN_SECONDS}s. Fetching status/alerts.`)
      return this.refresh()
    } else {
      console.log(`View is not stale ${this.secondsSinceLastRefresh()}s < ${STALE_THRESHOLD_IN_SECONDS}s.`)
    }
  }

  /*
   * Returns true if the notice should be displayed.
   *
   * The notice is shown if the page allows notices and a notice isn't already showing (so they don't overlap).
   */
  shouldDisplay() {
    return !(this.state.shouldHideNotices() || this.state.isNoticeShowing())
  }

  /*
   * Requests the latest data for the mower (status, alerts, job, plan). If found,
   * triggers a "new-content" event w/the new data object (a status, alert, or job).
   *
   * One event is triggered for each object type. For example, if a new status + alert report is
   * received, 2 events will be fired, one for each type.
   */
  refresh() {
    var _this = this;
    if (this.shouldDisplay()) this.$notice.removeClass("show").addClass("show")
    console.debug("Refreshing status + alerts + job + plan w/url=",this.mowerRefreshUrlWithParams())
    $.get(this.mowerRefreshUrlWithParams()).done(function(data) {
      console.log("received refresh data:",data)
      if (data.alert_report) {
        _this.lastAlertUpdatedAt= new Date(data.alert_report.created_at)
      }
      if (data.status) {
        _this.lastStatusUpdatedAt = new Date(data.status.attributes.created_at)
      }
      if (data.job) {
        _this.lastJobUpdatedAt = new Date(data.job.created_at)
      }
      if (data.plan) {
        _this.lastPlanUpdatedAt = new Date(data.plan.created_at)
      }
      $(window).trigger("new-data", data)
    }).always(function() {
      _this.$notice.removeClass("show")
      _this.lastRefreshAt = new Date()
    })
  }

  /*
   * The full URL for an HTTP GET to refresh data.
   */
  mowerRefreshUrlWithParams() {
    return this.mowerRefreshUrl + `?last_alert_updated_at=${this.lastAlertUpdatedAt.toISOString()}&last_status_updated_at=${this.lastStatusUpdatedAt.toISOString()}&last_job_updated_at=${this.lastJobUpdatedAt.toISOString()}&last_plan_updated_at=${this.lastPlanUpdatedAt.toISOString()}`
  }

  secondsSinceLastRefresh() {
    return (new Date() - this.lastRefreshAt)/1000
  }

  /*
   * Returns +true+ if data hasn't been received in a while.
   */
  isStale() {
    var stale = false
    if (this.secondsSinceLastRefresh() > STALE_THRESHOLD_IN_SECONDS) {
      stale = true
    }
    return stale
  }
}
